import React, { FC, ReactNode, useState, useEffect, RefObject } from "react";
import image4 from "images/umoya/SOLARIUM/4.jpeg";
import image3 from "images/umoya/SOLARIUM/3.jpeg";
// import sola from "images/umoya/solariumimage.jpg";
// import risto from "images/umoya/ristorante.jpg";
import lunita from "images/umoya/lunita.jpeg";
import HeroSearchForm, { SearchTab } from "components/HeroSearchForm/HeroSearchForm";
import HeroRealEstateSearchForm2 from "components/HeroSearchForm/HeroRealEstateSearchForm2";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionGridFeatureEventiServizi from "containers/PageHome/SectionGridFeatureEventiServizi";
import { Link, useHistory, useParams } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { useRef } from "react";
import moment from "moment";
import ExperiencesDateSingleInput from "components/HeroSearchForm/ExperiencesDateSingleInput";
import GuestsInputSolarium from "components/HeroSearchForm/GuestsInputSolarium";
import GuestsInputBambini from "components/HeroSearchForm/GuestsInputBambini";
import GuestsInputAnimali from "components/HeroSearchForm/GuestsInputAnimali";
import { GuestsInputPropsAdulti, GuestsInputPropsAnimali, GuestsInputPropsBambini, GuestsInputPropsTeen } from "containers/ListingDetailPage/ListingExperiencesDetailPage";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";
import CarCard from "components/CarCard/CarCard";
import ExperiencesCard from "components/ExperiencesCard/ExperiencesCard";
import { DEMO_EXPERIENCES_LISTINGS } from "data/listings";
import GuestsInputTeen from "components/HeroSearchForm/GuestsInputTeen";
import { title } from "process";

export interface SectionHeroArchivePageProps {
  className?: string;
  listingType?: ReactNode;
  currentPage: "Stays" | "Experiences" | "Cars" | "Flights";
  currentTab: SearchTab;
  label: string;
}

export interface HandlerTipologiaProps {
  tipologia_id?: number;
  evento_id?: number;
}

export interface CategoriesHome {
  id: number;
  button_evento: string;
  button_servizio: string;
  image_evento: string;
  image_servizio: string;
  sottotitolo_evento: string;
  sottotitolo_principale: string;
  sottotitolo_servizio: string;
  titolo_evento: string;
  titolo_principale: string;
  titolo_servizio: string;
}

const SectionHeroArchivePage: FC<SectionHeroArchivePageProps> = ({ className = "", label }) => {
  let temp = useParams<{ id: string }>();
  const id_evento = temp.id;

  const inputReference: RefObject<HTMLInputElement> = useRef(null);
  const [titolo_principale, setTitoloPrincipale] = useState("");
  const [sottotitolo_principale, setSottoTitoloPrincipale] = useState("");
  const [dateFocused, setDateFocused] = useState<boolean>(false);
  const [dateValue, setdateValue] = useState<moment.Moment | null>(moment());
  const [categories, setCategories] = useState(Array<any>());
  const [categoriesPrimary, setCategoriesPrimay] = useState(Array<any>());
  const [eventi, setEventi] = useState([]);
  const [isComponentVisible, setIsComponentVisible] = useState(false);
  const [adulti, setAdulti] = useState(1);
  const [teen, setTeen] = useState(0);
  const [bambini, setBambini] = useState(0);
  const [bambiniBaby, setBambiniBaby] = useState(0);
  const [tipologia, setTipologia] = useState<HandlerTipologiaProps>({});
  const [animali, setAnimali] = useState(false);
  const [dayEvent, setDayEvent] = useState<Array<string>>([]);
  const history = useHistory();

  useEffect(() => {
    if (isComponentVisible) inputReference.current?.scrollIntoView({ behavior: "smooth" });
  }, [isComponentVisible, eventi]);

  useEffect(() => {
    getHomePageSetting();
    getHomePagePrimarySetting();
    getDayEvents()
  }, []);

  const getHomePageSetting = () => {
    fetch(`https://booking.jooba.it/getHomePageSetting`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then(async (response) => {
        setCategories(response);
        setTitoloPrincipale(response[0].titolo_principale);
        setSottoTitoloPrincipale(response[0].sottotitolo_principale);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (tipologia.evento_id) {
      let info = {
        adulti: adulti,
        bambini: bambini,
        bambiniBaby: bambiniBaby,
        animali: animali,
        tipologia: tipologia,
      };

      history.push({
        pathname: `/dettaglio/${tipologia.evento_id}`,
        state: { info: info },
      });
    }
  }, [tipologia]);

  const getDayEvents = () => {
    fetch(`https://booking.jooba.it/events`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then(async (response) => {
        setDayEvent(response);
      })
      .catch((error) => console.log(error));
  };

  //Handler
  const handlerAdulti = (person: GuestsInputPropsAdulti) => {
    setAdulti(person.guestAdults || 1);
  };

  const handlerTeen = (person: GuestsInputPropsTeen) => {
    setTeen(person.guestTeen || 1);
  };

  const handlerBambini = (person: GuestsInputPropsBambini) => {
    setBambini(person.guestChildren || 0);
    setBambiniBaby(person.guestChildrenBaby || 0);
  };

  const handlerAnimali = (person: GuestsInputPropsAnimali) => {
    setAnimali(person.guestAnimals || false);
  };

  const tipoHandler = (tipologia: HandlerTipologiaProps) => {
    if (tipologia) setTipologia(tipologia);
  };
  //FINE HANDLER

  const renderHome = () => {
    return (
      <div className={`flex flex-col relative w-full lg:h-[380px] rounded-xl bg-primary-100 ${!isComponentVisible ? "mb-20" : ""} p-5 lg:p-20`}
        data-nc-id="SectionHeroArchivePage"
      >
        <h2 className="font-bold text-3xl md:text-4xl xl:text-6xl leading-[110%] text-center mb-4">TROVA COSA PUOI PRENOTARE</h2>
        <span className="text-center mt-4 font-medium lg:text-xl">
          Benvenuti nel portale di prenotazione di Umoya Natural Experience.
        </span>
        <span className="text-center font-bold lg:text-xl">
          Qui potrai prenotarti automaticamente oppure inviarci una richiesta di prenotazione.{" "}
        </span>
        <div className="lg:absolute w-full md:top-52 lg:top-64 lg:pr-40 left:0 mt-5 lg:mt-0">
          <div
            className="relative flex flex-col md:flex-row md:items-center rounded-xl shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-900 divide-y divide-neutral-200 dark:divide-neutral-700 md:divide-y-0 lg:h-[200px]"
            style={{ zIndex: 2 }}
          >
            <ExperiencesDateSingleInput
              defaultValue={dateValue}
              onChange={(date) => setdateValue(date)}
              defaultFocus={dateFocused}
              home={"home"}
              onFocusChange={(focus: boolean) => {
                setDateFocused(focus);
              }}
              dayEvent={dayEvent}
            />
            <GuestsInputSolarium
              defaultValue={{
                guestAdults: adulti,
                max_persone: 0,
                min_persone: 0,
              }}
              onChange={handlerAdulti}
            />
            {/* <GuestsInputTeen
              defaultValue={{
                guestTeen: teen,
                max_persone: 0,
                min_persone: 0,
              }}
              onChange={handlerTeen}
            /> */}
            <GuestsInputBambini
              defaultValue={{
                guestChildren: bambini,
                guestChildrenBaby: bambiniBaby,
                home: "home",
              }}
              onChange={handlerBambini}
            />
            <GuestsInputAnimali
              defaultValue={{
                guestAnimals: animali,
                animali_ammessi: 1,
              }}
              onChange={handlerAnimali}
            />
            <div className="px-4 py-4 lg:py-0">
              <button
                onClick={getServiceByFilter}
                type="button"
                className="h-14 md:h-16 w-full md:w-16 rounded-full bg-primary-6000 hover:bg-primary-700 flex items-center justify-center text-neutral-50 focus:outline-none"
              >
                <span className="mr-3 md:hidden">Cerca</span>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSubHome = () => {
    const info: any = {
      animali: animali,
      adulti: adulti,
      bambini: bambini,
      bambiniBaby: bambiniBaby,
    };
    const size = "default";
    return (
      <>
        {isComponentVisible && (
          <div className="flex flex-col justify-center items-center text-center py-10 lg:mt-20" ref={inputReference}>
            <div className="relative py-16 bg-primary-100 p-10 rounded-2xl" style={{ zIndex: 1 }}>
              <SectionGridFeatureEventiServizi
                info={info}
                stayListings={eventi}
                onClick={tipoHandler}
                heading={titolo_principale || ""}
                subHeading={sottotitolo_principale || ""}
              />
            </div>
          </div>
        )}
        <div className="mt-32 py-10 bg-gray-100 rounded-xl">
          <div className="container mx-auto px-4">
            <div className="flex justify-center mb-12">
              <h1 className="text-3xl font-bold animated-badge">
                Eventi in evidenza
              </h1>
            </div>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
              {categoriesPrimary
                .filter((e) => e.evidenza == 1)
                .map((e) => {
                  return (
                    <div
                      key={e.id}
                      className="nc-StayCard bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow flex flex-col justify-between"
                    >
                      <div className="relative w-full">
                        {e.image_copertina !== "" ? (
                          <img src={`https://private.jooba.it/image/homepage/${e.id}/${e.image}`} alt="umoya" />
                        ) : (
                          <img src={"images/sagra19.png"} alt="umoya" />
                        )}
                        <div className="p-3 space-y-2 space-x-2 flex flex-col">
                          <div className="text-center">
                            <h2 className="font-medium capitalize text-lg">
                              <span className="line-clamp-2">{e?.title}</span>
                            </h2>
                            <span className="line-clamp-2">{e?.note_generali}</span>
                          </div>
                        </div>
                        {((e?.note_prezzo != null && e?.note_prezzo != undefined && e?.note_prezzo != '') ||  (e?.note_prezzo_add != null && e?.note_prezzo_add != undefined && e?.note_prezzo_add != '')) &&
                        <div className="p-3 space-y-2 space-x-2 flex flex-col">
                          <div className="text-center">
                            <span className="font-medium line-clamp-2 text-primary-500">{e?.note_prezzo}</span>
                            <span className="font-medium line-clamp-2 text-primary-500">{e?.note_prezzo_add}</span>
                          </div>
                        </div>
                        }
                      </div>
                      <div className="p-3 space-y-2 space-x-2 flex flex-col w-full">
                        <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
                        <div className="text-neutral-500 dark:text-neutral-400 mt-4 text-sm space-x-2 w-full">
                          {!isNaN(parseInt(e.evento_id)) && e.tipo_link === "EVENTO_SINGOLO" && (
                            <Link to={{ pathname: `/dettaglio/${e.evento_id}` }}>
                              <ButtonPrimary className="w-full">{e.text_btn || "Clicca per maggiori informazioni e prenotazioni"}</ButtonPrimary>
                            </Link>
                          )}
                          {e.tipo_link === "SOTTOCATEGORIA" && isNaN(parseInt(e.evento_id)) && (
                            <Link to={{ pathname: `/home-evento` }}>
                              <ButtonPrimary className="w-full">{e.text_btn || "Clicca per maggiori informazioni e prenotazioni"}</ButtonPrimary>
                            </Link>
                          )}
                          {e.tipo_link === "EVENTI_SOTTOCATEGORIA" && !isNaN(parseInt(e.subcat_id)) && (
                            <Link to={{ pathname: `/eventi/${e.subcat_id}` }}>
                              <ButtonPrimary className="w-full">{e.text_btn || "Clicca per maggiori informazioni e prenotazioni"}</ButtonPrimary>
                            </Link>
                          )}
                          {e.tipo_link === "SERVIZI" && (
                            <Link to={{ pathname: `/home-evento` }}>
                              <ButtonPrimary className="w-full">{e.text_btn || "Clicca per maggiori informazioni e prenotazioni"}</ButtonPrimary>
                            </Link>
                          )}
                          {e.tipo_link === "BOOK_SERVICE" && (
                            <Link to={{ pathname: `/servizi` }}>
                              <ButtonPrimary className="w-full">{e.text_btn || "Clicca per maggiori informazioni e prenotazioni"}</ButtonPrimary>
                            </Link>
                          )}
                          {e.tipo_link === "URL" && (
                            <a href={e.url}>
                              <ButtonPrimary className="w-full">{e.text_btn || "Clicca per maggiori informazioni e prenotazioni"}</ButtonPrimary>
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>


        <div className="pt-10 pb-10">
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            {categoriesPrimary
              .filter((e) => {
                return e.evidenza == 0; //
              })
              .map((e) => {
                return (
                  <div
                    key={e.id}
                    className="flex flex-col p-5 mb-10 bg-orange-50 rounded-xl"
                    style={{ justifyContent: "space-between", height: "100%" }}
                  >
                    <div className="flex flex-col text-center space-y-8 mb-4 lg:mb-0">
                      <h2 className="font-medium text-4xl">{e?.title}</h2>
                    </div>
                    <div
                      className="flex flex-col text-justify md:text-lg dark:text-neutral-400"
                      style={{ wordWrap: "break-word", wordBreak: "break-word", whiteSpace: "pre-wrap" }}
                    >
                      <span>{e?.subtitle}</span>
                    </div>
                    <div className="flex flex-col w-full items-center">
                      {e.image != null && e.image !== "" && e.image !== undefined ? (
                        <img
                          src={`https://private.jooba.it/image/homepage/${e.id}/${e.image}`}
                          alt="umoya"
                          className="rounded-3xl mb-10"
                          style={{ maxWidth: "300px" }}
                        />
                      ) : e.id === 1 ? (
                        <img src={image4} alt="umoya" className="rounded-3xl mb-10" style={{ maxWidth: "300px" }} />
                      ) : e.id === 2 ? (
                        <img src={image3} alt="umoya" className="rounded-3xl mb-10" style={{ maxWidth: "300px" }} />
                      ) : e.id === 3 ? (
                        <img src={lunita} alt="umoya" className="rounded-3xl mb-10" style={{ maxWidth: "300px" }} />
                      ) : null}
                      {e.tipo_link === "EVENTO_SINGOLO" && !isNaN(parseInt(e.evento_id)) && (
                        <Link to={{ pathname: `/dettaglio/${e.evento_id}` }} className="w-full">
                          <ButtonPrimary className="w-full">{e.text_btn || "Clicca per maggiori informazioni e prenotazioni"}</ButtonPrimary>
                        </Link>
                      )}
                      {e.tipo_link === "SOTTOCATEGORIA" && (
                        <Link to={{ pathname: `/home-evento/${e.id}` }} className="w-full">
                          <ButtonPrimary className="w-full">{e.text_btn || "Clicca per maggiori informazioni e prenotazioni"}</ButtonPrimary>
                        </Link>
                      )}
                      {e.tipo_link === "EVENTI_SOTTOCATEGORIA" && !isNaN(parseInt(e.subcat_id)) && (
                        <Link to={{ pathname: `/eventi/${e.subcat_id}` }} className="w-full">
                          <ButtonPrimary className="w-full">{e.text_btn || "Clicca per maggiori informazioni e prenotazioni"}</ButtonPrimary>
                        </Link>
                      )}
                      {e.tipo_link === "SERVIZI" && (
                        <Link to={{ pathname: `/home-evento` }} className="w-full">
                          <ButtonPrimary className="w-full">{e.text_btn || "Clicca per maggiori informazioni e prenotazioni"}</ButtonPrimary>
                        </Link>
                      )}
                      {e.tipo_link === "BOOK_SERVICE" && (
                        <Link to={{ pathname: `/servizi` }} className="w-full">
                          <ButtonPrimary className="w-full">{e.text_btn || "Clicca per maggiori informazioni e prenotazioni"}</ButtonPrimary>
                        </Link>
                      )}
                      {e.tipo_link === "URL" && (
                        <a href={e.url} className="w-full">
                          <ButtonPrimary className="w-full">{e.text_btn || "Clicca per maggiori informazioni e prenotazioni"}</ButtonPrimary>
                        </a>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </>
    );
  };

  const renderCategories = () => {
    if (categories) {
      var cards: any = [];
      var categs: CategoriesHome[] = [];
      if (id_evento != '' && id_evento != null && id_evento != undefined) categs = categories.filter((e) => e.parent_id == id_evento);
      else categs = categories;
      categs.map((item: CategoriesHome) => {
        if (item.id > 1) {
          //1 sono i titoli
          cards.push(
            <div className={`nc-SectionHeroArchivePage flex flex-col relative ${className}`} data-nc-id="SectionHeroArchivePage" id={`${item.id}`}>
              <div className="flex flex-col text-center lg:flex-row lg:items-center lg:text-left">
                <div className="flex-shrink-0 lg:w-1/2 flex flex-col item-center lg:items-start space-y-6 lg:space-y-10 pb-14 lg:pb-64 xl:pb-80 xl:pr-14 lg:mr-10 xl:mr-0">
                  <h2 className="font-medium text-3xl md:text-4xl xl:text-6xl leading-[110%]">{item.titolo_evento}</h2>
                  <div
                    className="flex items-center text-base md:text-lg text-neutral-500 dark:text-neutral-400"
                    style={{ wordWrap: "break-word", wordBreak: "break-word", whiteSpace: "pre-wrap" }}
                  >
                    <span className="ml-2.5" dangerouslySetInnerHTML={{ __html: item.sottotitolo_evento }}></span>
                  </div>
                </div>
                <div className="flex-grow">
                  <img src={`https://private.jooba.it/image/homepageimage/evento/${item.image_evento}`} alt="umoya" className="w-full" />
                </div>
              </div>
              <div className="flow-root">
                <div className="z-10 lg:-mt-40 xl:-mt-56 w-full">
                  <HeroRealEstateSearchForm2 link={`eventi/${item.id}`} buttonLabel={item.button_evento} title={item.titolo_evento} />
                </div>
              </div>
            </div>
          );
        }
      });
      return cards;
    }
  };

  const getServiceByFilter = () => {
    const MySwal = withReactContent(Swal);
    const formattedDate = moment(dateValue).format("YYYY-MM-DD");

    fetch(`https://booking.jooba.it/getServiceByDate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: formattedDate,
        bambini: bambini + bambiniBaby,
        adulti: adulti,
        animali: animali,
      }),
    })
      .then((res) => res.json())
      .then(async (res) => {
        setEventi([]);
        if (res.evento) {
          setIsComponentVisible(true);
          setEventi(res.eventoData);
        } else {
          if (dateValue) {
            fetch(`https://booking.jooba.it/services`, {
              method: "GET",
            })
              .then((response) => response.json())
              .then(async (response) => {
                if (response) {
                  var myArray: any = [];
                  const promises = response.map((item: any) => {
                    const dateRest = moment(dateValue).format("DD/MM/YYYY");
                    return fetch(`https://booking.jooba.it/getTariffeService?data=${dateRest}&service=${item.riferimento_setting}`, {
                      method: "GET",
                    })
                      .then((res) => res.json())
                      .then(async (res) => {
                        if (res != 0) {
                          myArray.push({
                            id: item.id,
                            image: item.img,
                            dateValue: dateValue,
                            title: item.riferimento_setting,
                            link: "/servizi",
                          });
                        }
                      })
                      .catch((error) => console.log(error));
                  });

                  Promise.all(promises).then(() => {
                    if (myArray.length > 0) {
                      setIsComponentVisible(true);
                      setEventi(myArray);
                    } else {
                      MySwal.fire({
                        title: <p>Attenzione</p>,
                        html: <p>Non ci sono servizi disponibili in questa data!</p>,
                        icon: "warning",
                        confirmButtonColor: "#aa7832a8",
                        cancelButtonText: "Ok",
                        showCancelButton: false,
                        didOpen: () => { },
                      }).then((result) => {
                        setIsComponentVisible(false);
                      });
                    }
                  });
                }
              })
              .catch((error) => console.log(error));
          }
        }
      })
      .catch((error) => console.log(error));
  };

  const getHomePagePrimarySetting = () => {
    fetch(`https://booking.jooba.it/getHomePagePrimarySetting`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then(async (response) => {
        setCategoriesPrimay(response);
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="h-full">
      {label == "" && renderCategories()}
      {label == "home" && renderHome()}
      {label == "home" && renderSubHome()}
    </div>
  );
};

export default SectionHeroArchivePage;
