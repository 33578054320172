import React, { FC, ReactNode, useEffect, useState } from "react";
import HeaderFilter from "./HeaderFilter";
import StayCard from "components/StayCard/StayCard";
import StayCardMirko from "components/StayCard/StayCardMirko";
import { HandlerTipologiaProps } from "components/SectionHeroArchivePage/SectionHeroArchivePage";


export interface SectionGridFeatureEventiServiziProps {
  stayListings?: any[];
  gridClass?: string;
  heading?: ReactNode;
  onClick?: (tipologia: HandlerTipologiaProps) => void;
  info?: any;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
}

const SectionGridFeatureEventiServizi: FC<SectionGridFeatureEventiServiziProps> = ({

  stayListings = [],
  gridClass = "",
  heading = "Featured places to stay",
  subHeading = "Popular places to stay that Chisfis recommends for you",
  info = [],
  onClick,
  headingIsCenter,
  tabs = ["New York", "Tokyo", "Paris", "London"]

}) => {

  const tipoHandler = (tipologia: HandlerTipologiaProps) => {
    if (onClick) {
      if (tipologia) onClick(tipologia)
    }
  }

  const [validStays, setValidStays] = useState<{ [key: number]: boolean }>({});

  const renderCard = (stay: any) => {
    if (stay.title !== undefined) {
      return <StayCardMirko key={stay.id} info={info} data={stay} />;
    } else {
      return validStays[stay.id] ? (
        <StayCard onClick={tipoHandler} key={stay.id} info={info} data={stay} />
      ) : null;
    }
  };

  const checkExistTipologia = async (id: number) => {
    try {
      const response = await fetch(`https://booking.jooba.it/types?id=${id}`, {
        method: 'GET',
      });
      const data = await response.json();

      let check_exist_tipologia = 0;

      data.data.forEach((item: any) => {
        let bambini = 0;
        let bambiniBaby = 0;
        let animali = true;

        if (info) {
          bambini = info.bambini;
          bambiniBaby = info.bambiniBaby;
          animali = info.animali;
        }

        if (item?.bambini_ammessi === 0 && (bambini + bambiniBaby) > 0) {
          return;
        }
        if (item?.animali_ammessi === 0 && animali) {
          return;
        }

        check_exist_tipologia = 1;
      });

      return check_exist_tipologia;
    } catch (error) {
      console.log(error);
      return 0;
    }
  };

  useEffect(() => {
    const fetchValidStays = async () => {
      const results: { [key: number]: boolean } = {};
      for (const stay of stayListings) {
        if (stay.title === undefined) {
          const isValid = await checkExistTipologia(stay.id);
          results[stay.id] = isValid === 1;
        }
      }
      setValidStays(results);
    };

    fetchValidStays();
  }, [stayListings]);

  return (
    <div className="nc-SectionGridFeatureEventiServizi relative">
      <div className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ${gridClass}`}>
        {stayListings.map((stay) => renderCard(stay))}
      </div>
    </div>
  );
};

export default SectionGridFeatureEventiServizi;
