import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import FlightCardFaq from "components/FlightCard/FlightCardFaq";
import FlightCardNote from "components/FlightCard/FlightCardNote";
import image3 from "images/umoya/SOLARIUM/3.jpeg";
import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";

export interface ListingStayPageProps {
    className?: string;
}

const ListingStayPage: FC<ListingStayPageProps> = ({ className = "" }) => {
    const [faqs, setFaqs] = useState<any>([]);

    const getFAQ = () => {
        fetch(`https://booking.jooba.it/faqs`, {
          method: "GET",
        })
          .then((response) => response.json())
          .then(async (response) => {
            setFaqs(response)
          })
          .catch((error) => console.log(error));
    };

    useEffect(() => {

        getFAQ();

    }, []);

    return (
        <div
            className={`nc-ListingStayPage relative overflow-hidden ${className}`}
            data-nc-id="ListingStayPage"
        >
            <Helmet>
                <title>Umoya | Prenotazioni</title>
            </Helmet>
            <BgGlassmorphism />

            <div className="container relative overflow-hidden mb-20">
                {/* SECTION HERO */}


                <div className="flex flex-col justify-center items-center text-center py-20">
                    <div className="flex">
                        <h2 className="font-medium text-4xl md:text-5xl xl:text-7xl leading-[110%]">
                            Umoya Experience - FAQ
                        </h2>
                    </div>
                    <div className="flex text-base md:text-lg text-neutral-500 dark:text-neutral-400 mt-5">
                        <span className="ml-2.5">Domande Frequenti</span>
                    </div>
                </div>
                {faqs.map((item:any) => {
                    return (
                    <div className="py-2">
                        <FlightCardFaq data={{ titolo: item?.domanda, descrizione: item?.risposta }} />
                    </div>
                    );
                })}
            </div>
        </div>
    );
};

export default ListingStayPage;

