import React, { FC, useState } from "react";
import Parser from 'html-react-parser';

export interface FlightCardProps {
  className?: string;
  data: {
    titolo?: string;
    descrizione?: string;
  }
}

const FlightCardFaq: FC<FlightCardProps> = ({ className = "", data }) => {

  const [isOpen, setIsOpen] = useState(false);

  const renderDetail = () => {
    if (!isOpen) return null;
    return (
      <div className="p-4 md:p-4 border bg-white border-neutral-200 dark:border-neutral-700 rounded-2xl" >
        {/* {renderDetailTop()}
        <div className="my-7 md:my-10 space-y-5 md:pl-24">
          <div className="border-t border-neutral-200 dark:border-neutral-700" /> */}
          <div className="text-neutral-700 dark:text-neutral-300 text-sm md:text-base">
            <div className="text-neutral-6000 dark:text-neutral-300"> 
              <article lang="it" style={{ maxWidth:'1200px', width: '100%', textAlign:"justify", overflowWrap:"break-word"}}>
                <p style={{hyphens:"auto"}}>{Parser(data.descrizione || '')}</p> 
              </article>
            </div>
          </div>
          {/* <div className="border-t border-neutral-200 dark:border-neutral-700" />
        </div>
        {renderDetailTop()} */}
      </div>
    );
  };

  return (
    <div
      className={`nc-FlightCardgroup p-4 relative bg-neutral-200 dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-lg transition-shadow space-y-6 ${className}`} data-nc-id="FlightCard" /*style={{ backgroundColor:"#ff00006b" }}*/ >
      <div
        className={` sm:pr-20 relative  ${className}`} data-nc-id="FlightCard"
      >
        <a href="javascript:void(0)" onClick={() => setIsOpen(!isOpen)} className="absolute inset-0"></a>
         
        <span className={`absolute right-0 bottom-0 sm:bottom-auto sm:top-1/2 sm:-translate-y-1/2 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer ${isOpen ? "transform -rotate-180" : "" }`} onClick={() => setIsOpen(!isOpen)}>
          <i className="text-xl las la-angle-down"></i>
        </span>
        

        <div className="flex flex-col sm:flex-row sm:items-center">

          {/* FOR MOBILE RESPONSIVE */}
          <div className="block lg:hidden">
            <div className="flex font-semibold whitespace-nowrap">
                <span>{data.titolo}</span>
            </div>
          </div>

          {/* TIMME */}
          <div className="hidden lg:block flex-[4] font-semibold whitespace-nowrap">
            <span>{data.titolo}</span>
          </div>
        </div>
      </div>

      {/* DETAIL */}
      {renderDetail()}
    </div>
  );
};

export default FlightCardFaq;
