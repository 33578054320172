import React, { FC } from "react";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionGridEvents from "./SectionGridEvents";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

export interface ListingExperiencesMapPageProps {
  className?: string;
  referente?: string;
  codice_sconto?: string;
  id?: string;
}

const ListingExperiencesMapPage: FC<ListingExperiencesMapPageProps> = ({
  className = "",
}) => {

  const [events, setEvents] = useState([] as any);
  const [titolo, setTitolo] = useState('Tutti gli eventi');
  const location = useLocation<any>();

  useEffect(() => {

    if(location.state?.titolo) setTitolo(location.state?.titolo);

  }, [location]);



  let temp = useParams<{ id: string; referente: string; codice_sconto: string; category_id: string }>();
  const homepage_id = temp.id;
 
   React.useEffect(() => {
     if (temp?.referente) { 
       sessionStorage.setItem("referente", temp.referente);
       sessionStorage.setItem("codice_sconto", temp.codice_sconto); 
       // window.location.href = "http://prenotazioni.umoya.it/dettaglio/"+temp.id;
     }
   }, [temp]);

  useEffect(() => {
    getEvents();
  }, []);

  const getEvents = () => {
    fetch(`https://booking.jooba.it/events`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ 
        homepage_id: homepage_id
      })
    })
      .then((response) => response.json())
      .then(async (response) => {
        var obj:any = [];
        response.map((e:any) => {
          if(e.aperto != 6){ //Tutti gli eventi con stato 6 andranno sotto https://umoyadev.yood.it/
            obj.push(e);
          }
        })
        setEvents(obj);
      })
      .catch((error) => console.log(error));
  };

  //bg-[#ebd39fb0] da agggiungere nalla className se si vuole lo sfondo marrone
  return (
    <div
      className={`nc-ListingExperiencesMapPage bg-neutral-100 relative ${className}`}
      data-nc-id="ListingExperiencesMapPage"
    >
      <Helmet>
        <title>Umoya - Eventi</title>
      </Helmet>
      <BgGlassmorphism />
      <div className="container py-10">
        <SectionGridEvents events={events} titolo={titolo} />
      </div>
    </div>
  );
};

export default ListingExperiencesMapPage;
