import React, { FC, useEffect, useState } from "react";
import Heading2 from "components/Heading/Heading2";
import ExperiencesCardH from "components/ExperiencesCardH/ExperiencesCardH";
import { HandlerTipologiaProps } from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import { useHistory } from "react-router-dom";

export interface SectionGridHasMapProps {
  events:[{id: string}]
  titolo: string;
}

const SectionGridHasMap: FC<SectionGridHasMapProps> = ({events, titolo}) => {
  const [tipologia, setTipologia] = useState<HandlerTipologiaProps>({});
  const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1); 
  const history = useHistory();
  useEffect(() => {
    if (tipologia.evento_id) {
      let info = {
        adulti: null,
        bambini: null,
        bambiniBaby: null,
        animali: null,
        tipologia: tipologia,
      };

      history.push({
        pathname: `/dettaglio/${tipologia.evento_id}`,
        state: { info: info },
      });
    }
  }, [tipologia]);

  const tipoHandler = (tipologia: HandlerTipologiaProps) => {
    if (tipologia) setTipologia(tipologia);
  };

  return (
        <div className="w-full flex-shrink-0 xl:px-8 ">
          <Heading2 className="text-primary-6000" heading={titolo}
            subHeading={
              <span className="block mt-3">
                Disponibili n. {events.length} eventi
              </span>
            }
          />
          <div className="grid grid-cols-1 gap-8">
            {events.length>0 && events.map((item) => (
              <div
                key={item.id}
                onMouseEnter={() => setCurrentHoverID((_) => item.id)}
                onMouseLeave={() => setCurrentHoverID((_) => -1)}
              >
                <ExperiencesCardH {...item} _setTipologia={tipoHandler} />
              </div>
            ))}
          </div>
        </div>
  );
};

export default SectionGridHasMap;
