import React, { Fragment, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import { FC } from "react";
import ClearDataButton from "./ClearDataButton";
import { useLocation } from "react-router-dom";
import { State } from "containers/ListingDetailPage/ListingExperiencesDetailPage";


export interface GuestsInputPropsAdulti {
  defaultValue: {
    guestAdults?: number;
    max_persone?: number;
    min_persone?: number;
  };  

  onChange?: (data: GuestsInputPropsAdulti["defaultValue"]) => void;
  onBlur?: () => void;
  fieldClassName?: string;
}

const GuestsInputSolarium: FC<GuestsInputPropsAdulti> = ({
  defaultValue,
  onChange,
  onBlur,
  fieldClassName = "[ nc-hero-field-padding ]",
}) => {
  const location = useLocation<State>();
  const [guestAdultsInputValue, setGuestAdultsInputValue] = useState(0);
  const [max_persone, setMaxPersone] = useState(0);
  const [min_persone, setMinPersone] = useState(0);
  const [closed, setClosed] = useState(0);
  const totalGuests = guestAdultsInputValue;

  useEffect(() => {
    let adulti = 0

    if (location.state) {

      let state: any = null;
      state = location.state;
      if (state.info) {
        adulti = state.info.adulti;
      }

    }
    
    setGuestAdultsInputValue(defaultValue.guestAdults || adulti);
    setMaxPersone(defaultValue.max_persone || 0);
    setMinPersone(defaultValue.min_persone || 0);
  }, [defaultValue]);

  useEffect(() => {
    if (onChange) {
      onChange({
        guestAdults: guestAdultsInputValue
      });
    }
  }, [guestAdultsInputValue]);

  
  useEffect(() => {
    if(closed==1){
      if (onBlur) {
        onBlur()
      }
    }

  }, [closed]);

  const handleChange = (value: number) => {
    setGuestAdultsInputValue(value);
  }

  return (
    <Popover className="flex relative [ nc-flex-1 ]"    >
      {({ open }) => (
        <>
          <Popover.Button
            className={`flex text-left w-full flex-shrink-0 items-center ${fieldClassName} space-x-3 focus:outline-none cursor-pointer ${open ? "shadow-2xl rounded-full dark:bg-neutral-800" : ""
            }`}
          >
            <div className="text-neutral-300 dark:text-neutral-400" >
              <i className="las la-user-plus text-2xl mr-2"></i>
            </div>
            <div className="flex-grow">
              <span className="block xl:text-lg font-semibold">
                Adulti
              </span>
              <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                {totalGuests ? "Adulti: "+guestAdultsInputValue : "Aggiungi persona"}
              </span>
              {max_persone!=0 && open &&
                <span className="block mt-1 text-sm text-neutral-400 leading-none font-bold">
                  Max adulti: {max_persone}
                </span>
              }
              {min_persone !=0 && open &&
                <span className="block mt-1 text-sm text-neutral-400 leading-none font-bold" >
                  Min adulti: {min_persone}
                </span>
              }
              
              {!!totalGuests && open && (
                <ClearDataButton
                  onClick={() => {
                    setGuestAdultsInputValue(0);
                  }}
                />
              )}
            </div>
            
          </Popover.Button>
          
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
            afterLeave={()=>{setClosed(1)}}
            afterEnter={()=>{setClosed(0)}}
          >
            <Popover.Panel className="absolute z-10 bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl">
              <NcInputNumber
                className="w-full"
                min={min_persone}
                max={max_persone}
                defaultValue={guestAdultsInputValue}
                onChange={(value) => handleChange(value)}
                label="Adulti"
              />
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default GuestsInputSolarium;
