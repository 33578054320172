import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import Page404 from "containers/Page404/Page404";
import ListingExperiencesMapPage from "containers/ListingExperiencesPage/ListingExperiencesMapPage";
import ListingExperiencesDetailPage from "containers/ListingDetailPage/ListingExperiencesDetailPage";
import ListingExperiencesDetailPageSolarium from "containers/ListingDetailPage/ListingExperiencesDetailPageSolarium";
import CheckOutPageSolarium from "containers/CheckOutPage/CheckOutPageSolarium";
import CheckOutPage from "containers/CheckOutPage/CheckOutPage";
import SiteHeader from "containers/SiteHeader";
import ListingStayPage from "containers/ListingStayPage/ListingStayPage";
import FaqPage from "containers/FaqPage/FaqPage";
import CheckOutPageRistorante from "containers/CheckOutPage/CheckOutPageRistorante";
import PageAbout from "containers/PageAbout/PageAbout";
import PageVerificaStatoPrenotazione from "containers/PageAbout/PageVerificaStatoPrenotazione";
import ListingStayPageHome from "containers/ListingStayPage/ListingStayPageHome";
import ListingExperiencesDetailLodge from "containers/ListingDetailPage/ListingExperiencesDetailLodge";


export const pages: Page[] = [
  {
    path: "/",
    exact: true,
    component: ListingStayPageHome,
  },
  {
    path: "/home-evento",
    exact: true,
    component: ListingStayPage,
  },
  {
    path: "/eventi",
    exact: true,
    component: ListingExperiencesMapPage,
  },
  {
    path: "/dettaglio",
    exact: true,
    component: ListingExperiencesDetailPage,
  },
  {
    path: "/faq",
    exact: true,
    component: FaqPage,
  },
  { path: "/checkout", component: CheckOutPage },
  { path: "/servizi", component: ListingExperiencesDetailPageSolarium },
  { path: "/lodge", component: ListingExperiencesDetailLodge },
  { path: "/checkoutsolarium", component: CheckOutPageSolarium },
  { path: "/checkoutristorante", component: CheckOutPageRistorante },
   
];

const Routes = () => {
  return (
    <BrowserRouter basename="/">
      <ScrollToTop />
      <SiteHeader />

      <Switch>
        {pages.map(({ component, path, exact }) => {
          return <Route key={path} component={component} exact={!!exact} path={path} />;
        })}

        <Route exact path="/home-evento">
          <ListingStayPage />
        </Route>

        <Route exact path="/home-evento/:id">
          <ListingStayPage />
        </Route>

        <Route exact path="/dettaglio/:id">
          <ListingExperiencesDetailPage />
        </Route>
        <Route exact path="/agent/:referente/:codice_sconto/:id">
          <ListingStayPageHome />
        </Route>
        <Route exact path="/category/:referente/:codice_sconto/:id">
          <ListingExperiencesMapPage />
        </Route>
        <Route exact path="/eventi/:id/">
          <ListingExperiencesMapPage />
        </Route>

        <Route exact path="/cancellazione_rimborso/:evento_id">
          <PageAbout />
        </Route>

        <Route exact path="/stato_prenotazione">
          <PageVerificaStatoPrenotazione />
        </Route>

        <Route exact path="/stato_prenotazione/:codice">
          <PageVerificaStatoPrenotazione />
        </Route>

        <Route
          path="/home-return"
          component={() => {
            window.location.href = "http://www.umoya.it";
            return null;
          }}
        />

        <Route
          path="/privacy-policy"
          component={() => {
            window.location.href = "https://www.umoya.it/privacy-policy/";
            return null;
          }}
        />
        <Route component={Page404} />
      </Switch>

      <Footer />
    </BrowserRouter>
  );
};

export default Routes;
