import React, { FC, ReactNode, useEffect, Fragment, useState } from "react";
import { useParams } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import { tokenUmoya } from "utils/constants";
import defaultimage from "images/sagra19.png";
import Swal from 'sweetalert2'
import withReactContent from "sweetalert2-react-content";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import { Dialog, Transition } from "@headlessui/react";
import Badge from "shared/Badge/Badge";

export interface SectionHeroStatoPrenotazioneProps {
  className?: string;
  rightImg: string;
  heading: ReactNode;
  subHeading: string;
  btnText: string;
}

const SectionHeroStatoPrenotazione: FC<SectionHeroStatoPrenotazioneProps> = ({
  className = "",
  rightImg,
  heading,
  subHeading,
  btnText,
}) => {
  const [telefono, setTelefono] = useState('');
  const [codice, setCodice] = useState('');
  const [bs64codice, setBs64Code] = useState('');
  const [prenotazioni, setPrenotazione] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);

  let temp = useParams<{ codice: string }>();

  if (temp.codice) {

    if(codice == "") setCodice(temp.codice);

  }

  useEffect(() => {

    if (codice != '') {
      setBs64Code(btoa(codice));
    }

  }, [codice]);

  const renderSliderGallery = () => {
    return (
      <div className="hidden sm:block text-sm text-neutral-500 dark:text-neutral-400">
        <img src={defaultimage} alt="umoya" style={{ width: "150px" }} />
      </div>
    );
  };

  function closeModal() {
    setIsOpenModal(false);
  }

  function groupButton() {
    return (
      <ButtonPrimary onClick={() => { getStatus() }}>{btnText}</ButtonPrimary>
    );
  }

  useEffect(() => {

    if (telefono != '') {
      setTelefono(telefono.replace(/[^0-9\.-]/g, ''));
    }

  }, [telefono]);

  function getStatus() {
    const MySwal = withReactContent(Swal)
    if (telefono == '') return;
    fetch(`https://booking.jooba.it/bookingstatus?code=${codice}&telefono=${telefono}`, {
      method: "GET",
      headers: {
        'Key': tokenUmoya
      }
    })
      .then((response) => response.json())
      .then(async (response) => {
        if(codice == '') {
          if (response.length == 0) {
            MySwal.fire({
              title: <p>Ci dispiace</p>,
              html: <p>Non risultano prenotazioni al numero di telefono da te indicato</p>,
              icon: "info",
              confirmButtonColor: "#aa7832a8",
              didOpen: () => {
                //MySwal.clickConfirm()
              }
            }).then(() => {
              //return MySwal.fire(<p>Shorthand works too</p>)
            })
          } else if (response.length > 0) {
            setPrenotazione(response)
            setIsOpenModal(true);
          }
        } else {

          if (response.length > 0) {
            MySwal.fire({
              title: <p>Grazie</p>,
              html: <p>Lo stato della sua prenotazione è: <b>{response[0].stato}</b> <a className="px-8 py-3 relative h-auto inline-flex items-center justify-center rounded-full transition-colors ml-4 font-medium bg-neutral-300 border border-neutral-50 text-neutral-700 hover:bg-neutral-100"
              href={`https://prenotazioni.jooba.it/cancel?id=${bs64codice}`}>Annulla prenotazione</a></p>,
              icon: "info",
              confirmButtonColor: "#aa7832a8",
              didOpen: () => {
                //MySwal.clickConfirm()
              }
            }).then(() => {
              //return MySwal.fire(<p>Shorthand works too</p>)
            })
          } else {
            MySwal.fire({
              title: <p>Ci dispiace</p>,
              html: <p>Non risultano prenotazioni al numero di telefono da te indicato</p>,
              icon: "info",
              confirmButtonColor: "#aa7832a8",
              didOpen: () => {
                //MySwal.clickConfirm()
              }
            }).then(() => {
              //return MySwal.fire(<p>Shorthand works too</p>)
            })
          }
        }
      })
      .catch((error) => console.log(error));
  }

  const renderContent = (item: any) => {
    const temp_data = item.data_from;
    let anno = temp_data?.slice(0, 4);
    let mese = temp_data?.slice(5, 7);
    let giorno = temp_data?.slice(8, 10);
    const data_finale = giorno + '/' + mese + '/' + anno;
    return (
      <div className="flex-grow p-2 flex flex-col self-center">
        <div className="flex flex-col space-y-2">
          <div className="flex flex-row align-items-center">
            <h2 className="text-lg text-primary-6000 font-bold capitalize">
              {item.tipo_pren}
            </h2>
            <Badge className="animate-pulse py-2 ml-4" name={item.stato} color="yellow" />
          </div>
          <div className="space-x-2">
            <i className="las la-calendar text-lg"></i>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              {data_finale}
            </span>
          </div>
          <div>
            <a className="px-6 py-2 relative h-auto inline-flex items-center justify-center rounded-full font-medium bg-neutral-300 border border-neutral-50 hover:bg-neutral-100" href={`https://prenotazioni.jooba.it/cancel?id=${btoa(item.codice)}`}>Annulla prenotazione</a>
          </div>
        </div>
      </div>
    );
  };

  const renderModaleCompleto = () => {
    return (
      <div className="inline-block py-8 w-full" key={Math.random()}>
        <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
          <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
            <h3
              className="text-lg font-medium leading-6 text-gray-900"
              id="headlessui-dialog-title-70"
            >
              Le tue prenotazioni
            </h3>
            <span className="absolute left-3 top-3">
              <ButtonClose onClick={closeModal} />
            </span>
          </div>
          <div className="p-4">
            {prenotazioni.map((e:any) => {
              return(
              <div
                className={`nc-ExperiencesCardH group rounded-xl mb-5 relative bg-white dark:bg-neutral-900 border border-1 border-primary-500 overflow-hidden hover:shadow-xl transition-shadow flex flex-row`}
                data-nc-id="ExperiencesCardH" key={Math.random()}
              >
                {renderSliderGallery()}
                {renderContent(e)}
              </div>
            )})}
          </div>
        </div>
      </div>
    )
  }

  const renderModal = () => {
    return (
      <Transition appear show={isOpenModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              {renderModaleCompleto()}
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  return (
    <div
      className={`nc-SectionHero relative ${className}`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left">
        <div className="w-screen max-w-full xl:max-w-lg space-y-5 lg:space-y-7">
          <h2 className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100">
            {heading}
          </h2>
          <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
            {subHeading}
          </span>
          <div className="relative max-w-md">
            <Input
              required
              value={telefono}
              onInput={e => setTelefono((e.target as HTMLInputElement).value)}
              placeholder="Numero di telefono"
              type="text"
            />
          </div>
          {groupButton()}
        </div>
        {renderModal()}
        <div className="flex-grow">
          <img className="w-full" src={rightImg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default SectionHeroStatoPrenotazione;