import React from "react";
import { Link } from "react-router-dom";
import logoImg from "images/umoya/logo-nero.png";
import logoLightImg from "images/umoya/logo.png";
// import LogoSvgLight from "./LogoSvgLight";
// import LogoSvg from "./LogoSvg";

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
}

const Logo: React.FC<LogoProps> = ({
  img = logoImg,
  imgLight = logoLightImg,
  className = "",
}) => {
  return (
    <Link
      to="/"
      className={`ttnc-logo inline-block text-primary-6000 ${className}`}
    >
      {/* <LogoSvgLight />
      <LogoSvg /> */}

      {/* THIS USE FOR MY CLIENT */}
      {/* PLEASE UN COMMENT BELLOW CODE AND USE IT */}

        <img
          className={`block w-40`}
          src={img}
          alt="Logo"
        />
    </Link>
  );
};

export default Logo;
