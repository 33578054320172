import React, { FC } from "react";

export interface SaleOffBadgeProps {
  className?: string;
  desc?: string;
  icon?: boolean;
  colore?: string;
}

const SaleOffBadgeGrey: FC<SaleOffBadgeProps> = ({
  className = "",
  icon = false,
  desc = "-10% today",
  colore = "bg-gray-300"
}) => {
  return (
    <div
      className={`nc-SaleOffBadge flex items-center justify-center text-xs px-3 ${colore} rounded-full ${className}`}
      data-nc-id="SaleOffBadge"
    >
      {icon &&
      <i className="las la-mouse-pointer text-xl mr-2"></i>
      }
      {desc}
    </div>
  );
};

export default SaleOffBadgeGrey;
