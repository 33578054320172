import React, { FC } from "react";
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";
import SearchDropdown from "./SearchDropdown";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import MenuBar from "shared/MenuBar/MenuBar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";

export interface MainNav1Props {
  isTop: boolean;
  _isVisable: (isVisable: any) => void;
}

const MainNav1: FC<MainNav1Props> = ({ isTop, _isVisable }) => {
  return (
    <div className={`nc-MainNav1 relative z-10 ${isTop ? "onTop " : "notOnTop backdrop-filter" }`} >
      <div className="container py-5 relative flex justify-between items-center space-x-4 xl:space-x-8">
        <div className="flex justify-center ml-20 flex-grow items-center">
          <Logo />
          {/* <Navigation /> */}
        </div>
        <div className="flex-shrink-0 flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1">
          <div className="hidden items-center xl:flex space-x-1">
            {/* <SwitchDarkMode /> */}
            {/* <SearchDropdown /> */}
            <div className="px-1" />
            {/* <ButtonPrimary href="/login">Accedi</ButtonPrimary> */}
          </div>
          <div className="flex items-center" >
            {/* <SwitchDarkMode /> */}
            <div className="px-1" />
            <MenuBar _onClickOpen={(i) => _isVisable(i)} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav1;
