import React, { Fragment, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import { FC } from "react";
import ClearDataButton from "./ClearDataButton";
import Checkbox from "shared/Checkbox/Checkbox";
import { State } from "containers/ListingDetailPage/ListingExperiencesDetailPage";
import { useLocation } from "react-router-dom";


export interface GuestInputTipoRistorante {
  defaultValue: {
    guestTipoRistorante?: string;
    disponibile_cena?: string;
    disponibile_pranzo?: string;
  };

  onChange?: (data: GuestInputTipoRistorante["defaultValue"]) => void;
  onBlur?: () => void;
  fieldClassName?: string;
}

const GuestInputSceltaRistorante: FC<GuestInputTipoRistorante> = ({
  onChange,
  onBlur,
  fieldClassName = "[ nc-hero-field-padding ]",
  defaultValue
}) => {
  const disponibile_cena = defaultValue?.disponibile_cena;
  const disponibile_pranzo = defaultValue?.disponibile_pranzo;
  const [tipologia, setTipoRistorante] = useState('');
  const [clicked, setClicked] = useState(0);
  const [closed, setClosed] = useState(-1);

  const location = useLocation<State>();

  useEffect(() => {
    let ristoPranzoCena = '';

    if (location.state) {

      if(tipologia == '') {
        let state: any = null;
        state = location.state;
  
        if (state.info) {
          ristoPranzoCena = state.ristoPranzoCena;
          setTipoRistorante(ristoPranzoCena)
        }
  
  
        setClicked(1);
      }
    }
    
  }, [defaultValue]);


  useEffect(() => {
    if (onChange) {
      onChange({
        guestTipoRistorante: tipologia
      });
    }
  }, [tipologia]);


  useEffect(() => {
    if (closed == 1) {
      if (onBlur) {
        onBlur()
      }
    }

  }, [closed]);

  const buttonRef = React.useRef<HTMLButtonElement>(null);
  return (
    <Popover className="flex relative [ nc-flex-1 ]"    >
      {({ open }) => (
        <>
          <Popover.Button ref={buttonRef}
            className={`flex text-left w-full flex-shrink-0 items-center ${fieldClassName} space-x-3 focus:outline-none cursor-pointer ${open ? "shadow-2xl rounded-full dark:bg-neutral-800" : ""
              }`}
          >
            <div className="text-neutral-300 dark:text-neutral-400" >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="nc-icon-field"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
                />
              </svg>
            </div>
            <div className="flex-grow">
              <span className="block xl:text-lg font-semibold">
                Servizio
              </span>
              <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                {clicked == 0
                  ?
                  "Pranzo o cena?"
                  :
                  tipologia
                }
              </span>
            </div>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
            afterLeave={() => { setClosed(1) }}
            afterEnter={() => { setClosed(0) }}
          >
            <Popover.Panel className="absolute z-10 bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl">
              <div className="flex flex-col">
                {disponibile_pranzo == "SI" &&
                  <div className="flex flex-row" style={{ justifyContent: "space-between" }}>
                    <div>
                      <span className="font-medium text-neutral-800 dark:text-neutral-200">
                        Pranzo
                      </span>
                    </div>
                    <div className="ml-20">
                      <Checkbox
                        key={1}
                        className="w-full"
                        checked={tipologia == 'PRANZO' ? true : false}
                        defaultChecked={false}
                        label=""
                        name=""
                        onChange={() => { setTipoRistorante('PRANZO'); buttonRef.current?.click(); setClicked(1); }}
                      />
                    </div>
                  </div>
                }
                {disponibile_cena == "SI" &&
                  <div className="flex flex-row mt-4" style={{ justifyContent: "space-between" }}>
                    <div>
                      <span className="font-medium text-neutral-800 dark:text-neutral-200">
                        Cena
                      </span>
                    </div>
                    <div className="ml-20">
                      <Checkbox
                        key={2}
                        className="w-full"
                        checked={tipologia == 'CENA' ? true : false}
                        label=""
                        name=""
                        onChange={() => { setTipoRistorante('CENA'); buttonRef.current?.click(); setClicked(1); }}
                      />
                    </div>
                  </div>
                }

              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default GuestInputSceltaRistorante;
