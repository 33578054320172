import Badge from "shared/Badge/Badge";
import { Helmet } from "react-helmet";
import { FC, useEffect, useState } from "react";
import Parser from 'html-react-parser';

export interface dataPage {
  evento_id?: string;
}

const BlogSingle: FC<dataPage> = (data) => {

  const evento_id = data.evento_id;
  const [titoloCancellazione, setTitolo] = useState('');
  const [sottoTitoloCancellazione, setSottoTitolo] = useState('');
  const [testoCancellazione, setTestoCancellazione] = useState('');


  useEffect(() => {
    getCancellazioneRimborso();
  }, [data]);

  const getCancellazioneRimborso = () => {
    fetch(`https://booking.jooba.it/events`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: evento_id
      })
    })
      .then((response) => response.json())
      .then(async (response) => {
        setTitolo(response[0].titolo_cancellazione_rimborso);
        setSottoTitolo(response[0].sottotitolo_cancellazione_rimborso);
        setTestoCancellazione(response[0].cancellazione_rimborso);
      })
      .catch((error) => console.log(error));

  };



  const renderHeader = () => {
    return (
      <header className="container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5 text-center">
          <Badge href="##" color="purple" name="Cancellazione e rimborso" />
          <h1
            className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
          >
            {titoloCancellazione}
          </h1>
          <span className="block text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
            {sottoTitoloCancellazione}
          </span>

          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
          {/* <div className="flex flex-col items-baseline sm:flex-row sm:justify-between">
            <div className="nc-PostMeta2 flex items-center flex-wrap text-neutral-700 text-left dark:text-neutral-200 text-sm leading-none flex-shrink-0">
              <Avatar
                containerClassName="flex-shrink-0"
                sizeClass="w-8 h-8 sm:h-11 sm:w-11 "
              />
              <div className="ml-3">
                <div className="flex items-center">
                  <a
                    className="block font-semibold"
                    href="/ncmaz/author/the-demo-author-slug"
                  >
                    Fones Mimi
                  </a>
                </div>
                <div className="text-xs mt-[6px]">
                  <span className="text-neutral-700 dark:text-neutral-300">
                    May 20, 2021
                  </span>
                  <span className="mx-2 font-semibold">·</span>
                  <span className="text-neutral-700 dark:text-neutral-300">
                    6 min read
                  </span>
                </div>
              </div>
            </div>
            <div className="mt-3 sm:mt-0 sm:ml-3">
              <SocialsList />
            </div>
          </div> */}
        </div>
      </header>
    );
  };

  const renderContent = () => {
    return (
      <div
        id="single-entry-content"
        className="prose prose-sm mx-auto"
      >
        <article lang="it" style={{ maxWidth: '700px', width: '100%', textAlign: "justify", overflowWrap: "break-word" }}>
          <p style={{ hyphens: "auto" }}>{Parser(testoCancellazione || '')}</p>
        </article>

      </div>
    );
  };


  return (
    <div className="nc-PageSingle py-8 lg:py-16 ">
      <Helmet>
        <title>Umoya - Cencellazione e rimborso</title>
      </Helmet>
      {renderHeader()}

      <div className="nc-SingleContent container space-y-10">
        {renderContent()}
      </div>
    </div>
  );
};

export default BlogSingle;
