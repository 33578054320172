import React, { SelectHTMLAttributes, InputHTMLAttributes } from "react";

interface PhoneInputProps {
  prefixOptions: string[];
  selectedPrefix: string;
  phoneNumber: string;
  onPrefixChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  onPhoneNumberChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  selectProps?: SelectHTMLAttributes<HTMLSelectElement>;
}

const PhoneInput: React.FC<PhoneInputProps> = ({
  prefixOptions,
  selectedPrefix,
  phoneNumber,
  onPrefixChange,
  onPhoneNumberChange,
  inputProps = {},
  selectProps = {},
}) => {
  return (
    <div className="flex">
      <select
        value={selectedPrefix}
        onChange={onPrefixChange}
        className="block w-1/4 border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-l-2xl text-sm font-normal h-11 px-4 py-3"
        {...selectProps}
      >
        {prefixOptions.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
      <input
        type="text"
        value={phoneNumber}
        onChange={onPhoneNumberChange}
        className="block w-3/4 border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-r-2xl text-sm font-normal h-11 px-4 py-3"
        {...inputProps}
      />
    </div>
  );
};

export default PhoneInput;
