import React, { FC, useState } from "react";
import "react-dates/initialize";
import { Link } from "react-router-dom";
import Button from "shared/Button/Button";
import ButtonCircle from "shared/Button/ButtonCircle";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import RealEstateSearchForm from "./RealEstateSearchForm";


export interface HeroRealEstateSearchFormProps {
  className?: string;
  link?: string;
  title?: string;
  buttonLabel?: string;
}

const HeroRealEstateSearchForm2: FC<HeroRealEstateSearchFormProps> = ({
  className = "",
  link,
  title,
  buttonLabel
}) => {


  const renderForm = () => {
    return (
      <form className="w-full relative xl:mt-8 flex flex-col lg:flex-row lg:items-center rounded-3xl lg:rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-900 divide-y divide-neutral-200 dark:divide-neutral-700 lg:divide-y-0">
        <Link to={{ pathname: `/${link}`, state: {titolo: title} }} className="w-full">
          <ButtonPrimary className="w-full">{buttonLabel}</ButtonPrimary>
        </Link>
      </form>
    );

  };

  return (
    <div
      className={`nc-HeroRealEstateSearchForm w-full max-w-6xl py-5 lg:py-0 ${className}`}
      data-nc-id="HeroRealEstateSearchForm"
    >
      {renderForm()}
    </div>
  );
};

export default HeroRealEstateSearchForm2;
