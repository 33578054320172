import React, { FC } from "react";

export interface SaleOffBadgeProps {
  className?: string;
  icon?: boolean;
  desc?: string;
}

const SaleOffBadge: FC<SaleOffBadgeProps> = ({
  className = "",
  icon = false,
  desc = "-10% today",
}) => {
  return (
    <div className={`nc-SaleOffBadge flex items-center justify-center text-xs px-3 bg-green-700 text-red-50 rounded-full ${className}`}
      data-nc-id="SaleOffBadge"

    >
      {icon &&
        <i className="las la-mouse-pointer text-xl mr-2"></i>
      }
      {desc}
    </div>
  );
};

export default SaleOffBadge;
