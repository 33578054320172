import React, { Fragment, useEffect, useRef, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import { FC } from "react";
import ClearDataButton from "./ClearDataButton";
import Checkbox from "shared/Checkbox/Checkbox";

export interface GuestsInputProps {
  fieldClassName?: string;
  onChange?: (data: Array<TipologiaServiziType>) => void;
  options: any;
}


export interface TipologiaServiziType {
  id: string;
  tipologia_id: string;
  nome: string;
  isChecked: boolean;
  prezzo_adulto: string;
  prezzo_bambino: string;
  action: number;
  quantita: number;

}

const GuestsInputOptionTipology: FC<GuestsInputProps> = ({
  fieldClassName = "",
  onChange,
  options,
}) => {

  const [name, setCheckboxItem] = useState(Array<TipologiaServiziType>());
  const [indexSel, setIndexSel] = useState(-1);
  const [quantitaServizio, setQuantitaServizio] = useState(-1);

  useEffect(() => {

    options.map((item:any) => {
      name.push({id: item[0], tipologia_id: item[1], nome: item[2], isChecked:false, prezzo_adulto:item[3], prezzo_bambino:item[4], action:item[5], quantita:item[6]})
    })

  }, [options]);

 
  useEffect(() => {

    if(quantitaServizio != -1){
      const copied = [...name];
      copied[indexSel] = {id: copied[indexSel].id, tipologia_id: copied[indexSel].tipologia_id, isChecked: (quantitaServizio==0?false:true), nome: copied[indexSel].nome, prezzo_adulto: copied[indexSel].prezzo_adulto, prezzo_bambino: copied[indexSel].prezzo_bambino, action: copied[indexSel].action, quantita: quantitaServizio};
      
      let index = name.findIndex((e) => e.id == copied[indexSel].id);
      if(index == -1){
        setCheckboxItem(copied)
      } else {
        let Ar:TipologiaServiziType = copied[0];
        setCheckboxItem([]);
        setCheckboxItem([Ar]);
      }
      
    }
  }, [quantitaServizio]);

  useEffect(() => {
    if (onChange) {
      onChange(name);
    }
  }, [name]);

  const buttonRef = React.useRef<HTMLButtonElement>(null);

  return (
    <Popover className="flex relative [ nc-flex-1 ]">
      {({ open }) => (
        <>
          <Popover.Button ref={buttonRef} className={`flex text-left w-full flex-shrink-0 items-center ${fieldClassName} space-x-3 focus:outline-none cursor-pointer ${open ? "shadow-2xl rounded-full dark:bg-neutral-800" : "" }`}>
            <div className="text-neutral-300 dark:text-neutral-400">
              <i className="las la-wine-glass text-2xl mr-2"></i>
            </div> 
            <div className="flex-grow">
              <span className="block xl:text-lg font-semibold">
                Servizi disponibili
              </span>
              <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                {name[indexSel]?.quantita>0 && name[indexSel]?.nome +': x'+ name[indexSel]?.quantita }
              </span>
            </div>
          </Popover.Button>
          
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute right-0 z-10 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl">

            {name.map((item, index)=> {

              return (
                <div className="flex flex-row">
                  <NcInputNumber
                    className="w-full"
                    defaultValue={0}
                    onChange={(value) => {
                      setQuantitaServizio(value);
                      setIndexSel(index);
                    }}
                    label={item.nome}
                    key={index}
                    // desc="Fino a 12 anni"
                  />
                </div>
              )
            })}            

            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default GuestsInputOptionTipology;
