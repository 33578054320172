import React, { Fragment, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { FC } from "react";
import Checkbox from "shared/Checkbox/Checkbox";
import { useLocation } from "react-router-dom";
import { State } from "containers/ListingDetailPage/ListingExperiencesDetailPage";

export interface GuestsInputPropsBAmbini {
  defaultValue: {
    bambini_ammessi?: number;
    guestBambiniCk?: boolean;
    clicked?: boolean
  };

  onChange?: (data: GuestsInputPropsBAmbini["defaultValue"]) => void;
  onBlur?: () => void;
  fieldClassName?: string;
}

const GuestsInputBamibiCheck: FC<GuestsInputPropsBAmbini> = ({
  defaultValue,
  onChange,
  onBlur,
  fieldClassName = "[ nc-hero-field-padding ]",
}) => {
  const location = useLocation<State>();

  const [guestBamibiniTrue, setGuestBamibiniTrue] = useState(false);
  const [guestBamibiniFalse, setGuestBamibiniFalse] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [closed, setClosed] = useState(0);
  const [setted, setSetted] = useState(false);

  useEffect(() => {

    if (defaultValue.bambini_ammessi == 0) {
      setGuestBamibiniFalse(true);
      setClicked(true);
      if (onChange) onChange({
        guestBambiniCk: false,
        clicked: clicked
      });
    } else {
      if (location.state) {
        if(!setted) {
          let state: any = null;
          state = location.state;
          setSetted(true);
          if (state.info) {
            let bambini = state.info.bambini;
            let bambiniBaby = state.info.bambiniBaby;
            if (bambiniBaby + bambini > 0) {
              setGuestBamibiniTrue(true)
              setGuestBamibiniFalse(false)
            } else {
              setGuestBamibiniTrue(false)
              setGuestBamibiniFalse(true)
            }
            setClicked(true);
          }
        }     
      }
    }


  }, [defaultValue]);

  useEffect(() => {

    if (onChange) {
      if (guestBamibiniTrue == true) {
        onChange({
          guestBambiniCk: true,
          clicked: clicked
        });
      } else if (guestBamibiniFalse == true) {
        onChange({
          guestBambiniCk: false,
          clicked: clicked
        });
      }
    }

  }, [guestBamibiniTrue, guestBamibiniFalse]);


  useEffect(() => {
    if (closed == 1) {
      if (onBlur) {
        onBlur()
      }
    }

  }, [closed]);
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  return (
    <Popover className="flex relative [ nc-flex-1 ]"    >
      {({ open }) => (
        <>
          <Popover.Button ref={buttonRef}
            disabled={defaultValue.bambini_ammessi != 0 ? false : true}
            className={`flex text-left w-full flex-shrink-0 items-center ${fieldClassName} space-x-3 focus:outline-none cursor-pointer ${open ? "shadow-2xl rounded-full dark:bg-neutral-800" : ""
              }`}
          >
            <div className="text-neutral-300 dark:text-neutral-400" >

              <i className="las la-question-circle text-2xl mr-2"></i>
            </div>
            <div className="flex-grow">
              <span className="block xl:text-lg font-semibold">
                Bambini
              </span>
              <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                {guestBamibiniTrue === false && guestBamibiniFalse === false &&
                  "Porterai con te bambini?"
                }
                {guestBamibiniTrue === true &&
                  "Si"
                }
                {guestBamibiniFalse === true &&
                  <>
                    {defaultValue.bambini_ammessi == 0 ? "Non ammessi" : "No"}
                  </>
                }
              </span>
            </div>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
            afterLeave={() => { setClosed(1) }}
            afterEnter={() => { setClosed(0) }}
          >
            <Popover.Panel className="absolute z-10 bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl">
              {defaultValue.bambini_ammessi == 1 ?
                <div className="flex flex-col">
                  <div className="flex flex-row">
                    <div className="flex-1">
                      <span className="font-medium text-neutral-800 dark:text-neutral-200">
                        Si
                      </span>
                    </div>
                    <div className="flex-1 ml-20">
                      <Checkbox
                        className="w-full"
                        checked={guestBamibiniTrue ? true : false}
                        defaultChecked={false}
                        key={1}
                        label=""
                        name=""
                        onChange={() => { setGuestBamibiniTrue(guestBamibiniTrue == true ? false : true); setGuestBamibiniFalse(guestBamibiniTrue == true ? true : false); buttonRef.current?.click(); setClicked(true); }}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row mt-4">
                    <div className="flex-1">
                      <span className="font-medium text-neutral-800 dark:text-neutral-200">
                        No
                      </span>
                    </div>
                    <div className="flex-1 ml-20">
                      <Checkbox
                        className="w-full"
                        checked={guestBamibiniFalse ? true : false}
                        defaultChecked={false}
                        key={2}
                        label=""
                        name=""
                        onChange={() => { setGuestBamibiniFalse(guestBamibiniFalse == true ? false : true); setGuestBamibiniTrue(guestBamibiniFalse == true ? true : false); buttonRef.current?.click(); setClicked(true); }}
                      />
                    </div>
                  </div>
                </div>
                :
                <div className="flex flex-col">
                  <div className="flex flex-row">
                    <div className="flex-1">
                      <span className="font-medium text-neutral-800 dark:text-neutral-200">
                        Si
                      </span>
                    </div>
                    <div className="flex-1 ml-20">
                      <Checkbox
                        className="w-full"
                        checked={false}
                        defaultChecked={false}
                        key={1}
                        label=""
                        name=""
                      />
                    </div>
                  </div>
                  <div className="flex flex-row mt-4">
                    <div className="flex-1">
                      <span className="font-medium text-neutral-800 dark:text-neutral-200">
                        No
                      </span>
                    </div>
                    <div className="flex-1 ml-20">
                      <Checkbox
                        className="w-full"
                        checked={true}
                        defaultChecked={true}
                        key={2}
                        label=""
                        name=""
                      />
                    </div>
                  </div>
                </div>
              }
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default GuestsInputBamibiCheck;
