import React, { Fragment, useEffect, useRef, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { FC } from "react";
import Checkbox from "shared/Checkbox/Checkbox";

export interface GuestsInputProps {
  fieldClassName?: string;
  onChange?: (data: string, index: number) => void;
  tipologies: any;
  titolo?: string;
  indexDef?: number;
  data?: string;
}


export interface TipologiaType {
  
  nome: string;
  isChecked: boolean;
  stato: string;
  etichetta: string;
  labelStato: string;
  ore_fine_type:string;
  ore_inizio_type:string;

}

export interface TipologiaTypeDue {
  
  nome: string;
  isChecked: boolean;
  stato: string;
  labelStato: string;
  ore_fine_type:string;
  ore_inizio_type:string;


}

const GuestsInput: FC<GuestsInputProps> = ({
  fieldClassName = "",
  onChange,
  indexDef,
  tipologies,
  data
}) => {

  const name = Array<TipologiaTypeDue>();
  const [tipologia,setTipologia]=useState('')
  const [index, setIndex] = useState(-1)
  const [checked, setChecked] = useState(-1)

  tipologies.map((item:any) => {
 
    let etichetta = item[3]?.filter((x:any) => {

      if(item[2] == 'APERTO' && x.stato_id == 5) {
        return x;

      } else if(item[2] == 'ATTESA' && x.stato_id == 6) {
        return x;

      } else if(item[2] == 'RICHIESTA' && x.stato_id == 7) {
        return x;
        
      } else if(item[2] == 'CHIUSO' && x.stato_id == 8) {
        return x;

      } else return null;
      
    });

    let label = '';
    if(etichetta != null) {
      if(etichetta.length>0) label = etichetta[0].etichetta_stato;
    }

    var nomeServ = (item[1]!="")?item[1]:item[0];

    if(item[2] == 'APERTO'){    

      name.push({nome: nomeServ,isChecked:false, stato:item[2],labelStato:label, ore_inizio_type: item[4], ore_fine_type: item[5]})
    } else if(item[2] == 'RICHIESTA'){
      name.push({nome: nomeServ, isChecked:false, stato:item[2], labelStato:label, ore_inizio_type: item[4], ore_fine_type: item[5]})
    } else if(item[2] == 'ATTESA'){
      name.push({nome: nomeServ, isChecked:false, stato:item[2], labelStato:label, ore_inizio_type: item[4], ore_fine_type: item[5]})
    } else if(item[2] == 'CHIUSO'){
      name.push({nome: nomeServ,  isChecked:false, stato:item[2], labelStato:label, ore_inizio_type: item[4], ore_fine_type: item[5]})
    }
    
  })

  useEffect(() => {

    if(indexDef != undefined && indexDef != -1) {

      let label = tipologies[indexDef][1];
      if(label == "") label = tipologies[indexDef][0];

      setChecked(indexDef);
      setTipologia(label); // [1] -> label del nome
      setIndex(indexDef); 
    } 

  }, [indexDef]);

  useEffect(() => {

    if(indexDef == undefined || indexDef == -1) {
      setChecked(-1);
      setTipologia('');
      setIndex(-1);
    } 

  }, [data]);

  useEffect(() => {
    if (onChange) {
      onChange(tipologia, index);
    }
  }, [tipologia]);

  const buttonRef = React.useRef<HTMLButtonElement>(null);
  
  return (
    <Popover className="flex relative [ nc-flex-1 ]">
      {({ open }) => (
        <>
          <Popover.Button ref={buttonRef} className={`flex text-left w-full flex-shrink-0 items-center ${fieldClassName} space-x-3 focus:outline-none cursor-pointer ${open ? "shadow-2xl rounded-full dark:bg-neutral-800" : "" }`}>
            <div className="text-neutral-300 dark:text-neutral-400">
              <i className="las la-glass-martini-alt text-2xl mr-2"></i>
            </div>
            <div className="flex-grow">
              <span className="block xl:text-lg font-semibold">
                Tipologia
              </span>
              <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                {tipologia != "" ? tipologia : "Seleziona tipologia"}
              </span>
            </div>
          </Popover.Button>
          
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10 bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl space-y-2">
            {name.map((item, index)=> {
             return  <>
             {item.stato != "CHIUSO"
             ?
              <Checkbox
                className="w-full mt-2"
                checked={index==checked ? true :false}
                label={item.nome}
                subLabel={item.labelStato}
                name={item.nome}
                key={index}
                ore_inizio_type={item.ore_inizio_type}
                ore_fine_type={item.ore_fine_type}
                onChange={()=>{
                  setChecked(index);
                  setTipologia(item.nome);
                  setIndex(index); 
                  buttonRef.current?.click();
                }}
              />
             :
              <Checkbox
                className="w-full mt-2"
                checked={false}
                label={item.nome}
                subLabel={item.labelStato}
                ore_inizio_type={item.ore_inizio_type}
                ore_fine_type={item.ore_fine_type}
                name={item.nome}
                key={index}
              />
             }
            </>
            

            })}            

            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default GuestsInput;
