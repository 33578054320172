import React, { Fragment, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { FC } from "react";
import Checkbox from "shared/Checkbox/Checkbox";
import { useLocation } from "react-router-dom";
import { State } from "containers/ListingDetailPage/ListingExperiencesDetailPage";


export interface GuestsInputPropsAnimali {
  defaultValue: {
    guestAnimals?: boolean;
    bambini?: number;
    bambiniBaby?: number;
    animali_ammessi?: number;
  };

  onChange?: (data: GuestsInputPropsAnimali["defaultValue"]) => void;
  onBlur?: () => void;
  fieldClassName?: string;
}

const GuestsInputAnimali: FC<GuestsInputPropsAnimali> = ({
  defaultValue,
  onChange,
  onBlur,
  fieldClassName = "[ nc-hero-field-padding ]",
}) => {
  const location = useLocation<State>();
  const [guestAnimalsTrue, setGuestAnimalsTrue] = useState(false);
  const [guestAnimalsFalse, setGuestAnimalsFalse] = useState(false);
  const [closed, setClosed] = useState(0);
  const [setted, setSetted] = useState(false);

  useEffect(() => {
    //setGuestAnimalsInputValue(defaultValue.guestAnimals || false);
    if (defaultValue.animali_ammessi == 0) {
      setGuestAnimalsFalse(true)
      if (onChange) onChange({
        guestAnimals: false
      });
    } else {
      if (location.state) {
  
        if (!setted) {
          setSetted(true);
          let state: any = null;
          state = location.state;
  
          if (state.info) {
            let animali = state.info.animali;
  
            if (animali) {
              setGuestAnimalsTrue(true);
              setGuestAnimalsFalse(false);
            } else {
              setGuestAnimalsTrue(false);
              setGuestAnimalsFalse(true);
            }
  
          }
        }
  
      }
    }
  }, [defaultValue]);

  useEffect(() => {
    if (onChange) {
      if (guestAnimalsTrue == true) {
        onChange({
          guestAnimals: true
        });
      } else if (guestAnimalsFalse == true) {
        onChange({
          guestAnimals: false
        });
      }
    }
  }, [guestAnimalsTrue, guestAnimalsFalse]);


  useEffect(() => {
    if (closed == 1) {
      if (onBlur) {
        onBlur()
      }
    }

  }, [closed]);

  return (
    <Popover className="flex relative [ nc-flex-1 ]"    >
      {({ open }) => (
        <>
          <Popover.Button
            disabled={defaultValue.animali_ammessi != 0 ? false : true}
            className={`flex text-left w-full flex-shrink-0 items-center ${fieldClassName} space-x-3 focus:outline-none cursor-pointer ${open ? "shadow-2xl rounded-full dark:bg-neutral-800" : ""
              }`}
          >
            <div className="text-neutral-300 dark:text-neutral-400" >
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                className="nc-icon-field"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
                />
              </svg> */}
              <i className="las la-dog text-2xl mr-2"></i>
            </div>
            <div className="flex-grow">
              <span className="block xl:text-lg font-semibold">
                Animali
              </span>
              <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                {guestAnimalsTrue === true && defaultValue.animali_ammessi != 0 &&
                  "Si"
                }
                {guestAnimalsFalse === true &&
                  <>
                    {defaultValue.animali_ammessi == 0 ? "Non ammessi" : "No"}
                  </>
                }
                {guestAnimalsFalse === false && guestAnimalsTrue === false && defaultValue.animali_ammessi != 0 &&
                  "Porterai con te animali?"
                }
              </span>

              {/* {!!totalGuests && open && (
                <ClearDataButton
                  onClick={() => {
                    setGuestAnimalsInputValue(false);
                  }}
                />
              )} */}
            </div>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
            afterLeave={() => { setClosed(1) }}
            afterEnter={() => { setClosed(0) }}
          >
            <Popover.Panel className="absolute z-10 bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl">
              {defaultValue.animali_ammessi == 1 ?
                <div className="flex flex-col">
                  <div className="flex flex-row">
                    <div className="flex-1">
                      <span className="font-medium text-neutral-800 dark:text-neutral-200">
                        Si
                      </span>
                    </div>
                    <div className="flex-1 ml-20">
                      <Checkbox
                        className="w-full"
                        checked={guestAnimalsTrue ? true : false}
                        defaultChecked={false}
                        key={1}
                        label=""
                        name=""
                        onChange={() => { setGuestAnimalsTrue(guestAnimalsTrue == true ? false : true); setGuestAnimalsFalse(guestAnimalsTrue == true ? true : false); }}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row mt-4">
                    <div className="flex-1">
                      <span className="font-medium text-neutral-800 dark:text-neutral-200">
                        No
                      </span>
                    </div>
                    <div className="flex-1 ml-20">
                      <Checkbox
                        className="w-full"
                        checked={guestAnimalsFalse ? true : false}
                        defaultChecked={false}
                        key={2}
                        label=""
                        name=""
                        onChange={() => { setGuestAnimalsFalse(guestAnimalsFalse == true ? false : true); setGuestAnimalsTrue(guestAnimalsFalse == true ? true : false); }}
                      />
                    </div>
                  </div>
                </div>
                :
                <div className="flex flex-col">
                  <div className="flex flex-row">
                    <div className="flex-1">
                      <span className="font-medium text-neutral-800 dark:text-neutral-200">
                        Si
                      </span>
                    </div>
                    <div className="flex-1 ml-20">
                      <Checkbox
                        className="w-full"
                        checked={false}
                        defaultChecked={false}
                        key={1}
                        label=""
                        name=""
                      />
                    </div>
                  </div>
                  <div className="flex flex-row mt-4">
                    <div className="flex-1">
                      <span className="font-medium text-neutral-800 dark:text-neutral-200">
                        No
                      </span>
                    </div>
                    <div className="flex-1 ml-20">
                      <Checkbox
                        className="w-full"
                        checked={true}
                        defaultChecked={true}
                        key={2}
                        label=""
                        name=""
                      />
                    </div>
                  </div>
                </div>
              }
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default GuestsInputAnimali;
