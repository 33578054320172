import React, { useEffect, useState } from "react";
import { SingleDatePicker, AnchorDirectionShape } from "react-dates";
import { FC } from "react";
import ClearDataButton from "./ClearDataButton";
import moment, { Moment } from "moment";
import useWindowSize from "hooks/useWindowResize";

export interface ExperiencesDateSingleInputProps {
  defaultValue: moment.Moment | null;
  onChange?: (date: moment.Moment | null) => void;
  defaultFocus?: boolean;
  fieldClassName?: string;
  home?: string;
  onFocusChange: (focused: boolean) => void;
  className?: string;
  anchorDirection?: AnchorDirectionShape;
  dayEvent?: string[];
  checkDisp?: boolean
}

const ExperiencesDateSingleInput: FC<ExperiencesDateSingleInputProps> = ({
  defaultValue,
  onChange,
  defaultFocus = false,
  onFocusChange,
  home = null,
  anchorDirection,
  className = "",
  fieldClassName = "[ nc-hero-field-padding ]",
  dayEvent,
  checkDisp = true
}) => {
  const [focusedInput, setFocusedInput] = useState(defaultFocus);
  const [startDate, setStartDate] = useState(defaultValue);  
  const [daysEnable, setDaysEnable] = useState<string[]>([]);

  const windowSize = useWindowSize();

  useEffect(() => {
    setStartDate(defaultValue);
    getDaysEnable();
  }, [defaultValue]);

  
  const getDaysEnable = () => { 
    fetch(`https://booking.jooba.it/getDaysEnable`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then(async (response) => {    
        setDaysEnable(response);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    setFocusedInput(defaultFocus);
  }, [defaultFocus]);

  useEffect(() => {
    if (onChange) {
      onChange(startDate);
    }
  }, [startDate]);

  const handleClearData = () => {
    setStartDate(null);
  };

  const handleDateFocusChange = (arg: { focused: boolean }) => {
    setFocusedInput(arg.focused);
    onFocusChange && onFocusChange(arg.focused);
  };

  const renderInputCheckInDate = () => {
    const focused = focusedInput;
    let dataSel = startDate ? startDate.format("DD MMMM").replace(/ /g, '&nbsp;') : "Data";
    return (
      <div
        className={`flex w-full relative ${fieldClassName} items-center space-x-3 cursor-pointer ${focused ? "shadow-sm rounded-full" : ""}`}
        onClick={() => handleDateFocusChange({ focused: focused ? false : true })}
      >
        <div className="text-neutral-300 dark:text-neutral-400 mr-3">
          <svg xmlns="http://www.w3.org/2000/svg" className="nc-icon-field" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
        </div>
        <div className="flex-grow">
          <p className="block xl:text-lg text-left font-semibold"style={{ wordBreak: 'normal', textTransform: 'capitalize' }} dangerouslySetInnerHTML={{ __html: dataSel }}></p>
          <span className="block mt-1 text-sm text-left text-neutral-400 leading-none font-light">
            {startDate ? "Selezionato" : home != null ? "Seleziona" : `Seleziona una data per continuare`}
          </span>
          {startDate && focused && <ClearDataButton onClick={() => handleClearData()} />}
        </div>
      </div>
    );
  };

  const renderDay = (day: any) => {
    const today = moment().startOf('day'); // Imposta 'oggi' all'inizio della giornata
    const enabledDates = dayEvent || [];
    let dayBackgroundColor;
    let color;

    const isFutureOrToday = day.isSameOrAfter(today, 'day');
    const enabledDatesBE = new Set(daysEnable); 
    const dayString = day.format('YYYY-MM-DD');
    var isEnabled = false;
    if (dayString) isEnabled = enabledDatesBE.has(dayString);

    if(isEnabled) {
      if (checkDisp) {
        // Se la data è futura o oggi
        if (isFutureOrToday) {
          dayBackgroundColor = enabledDates.includes(day.format("YYYY-MM-DD")) ? "#BAA273" : "#b8daba";
          color = enabledDates.includes(day.format("YYYY-MM-DD")) ? "#fff" : "#000";
        } else {
          // Se la data è passata
          dayBackgroundColor = "#D3D3D3";
          color = "#A9A9A9"; // Un grigio scuro per le date passate
        }
      } else {
        const isWeekday = day.isoWeekday() >= 1 && day.isoWeekday() <= 5;
        if (isFutureOrToday) {
          dayBackgroundColor = isWeekday ? "#BAA273" : "#b8daba";
          color = isWeekday ? "#000" : "#D3D3D3";
        } else {
          dayBackgroundColor = "#D3D3D3";
          color = "#A9A9A9";
        }
      }
    } else {
      dayBackgroundColor = "#D3D3D3";
      color = "#A9A9A9";
    }

    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: dayBackgroundColor, height: "100%", color: color }}>
        <span>{day.format("D")}</span>
      </div>
    );
  };

  // Funzione per verificare se la data è abilitata
  const isEnabledDate = (day: any) => {
    const today = moment().startOf('day'); // Imposta 'oggi' all'inizio della giornata
    const enabledDates = new Set(daysEnable); // Usa un Set per un rapido accesso alle date abilitate
    const dayString = day.format('YYYY-MM-DD'); // Converti la data in stringa nel formato 'YYYY-MM-DD'
    var isEnabled = false;
    // Verifica se la data è nell'array dei giorni abilitati
    if (dayString) isEnabled = enabledDates.has(dayString);

    if (checkDisp) {
      // Se la data è futura o oggi
      return isEnabled && day.isSameOrAfter(today, 'day');
    } else {
      // Se la data è passata, controlla se è un giorno lavorativo
      const isFutureOrToday = day.isSameOrAfter(today, 'day');
      const isWeekday = day.isoWeekday() >= 1 && day.isoWeekday() <= 5;

      return isEnabled && (isFutureOrToday ? isWeekday : false);
    }
  };

  // Funzione per bloccare i giorni non abilitati
  const isOutsideRange = (day: any) => {
    return !isEnabledDate(day); // Blocca le date che non sono abilitate
  };

  return (
    <div className={`relative flex ${className}`} style={{ flex: "1 0 0%" }}>
      <div className="absolute inset-x-0 bottom-0">
        <SingleDatePicker
          date={startDate}
          onDateChange={(date) => setStartDate(date)}
          id={"nc-hero-ExperiencesDateSingleInput-startDateId"}
          focused={focusedInput}
          daySize={windowSize.width > 425 ? 56 : undefined}
          orientation={"horizontal"}
          onFocusChange={handleDateFocusChange}
          noBorder
          hideKeyboardShortcutsPanel
          numberOfMonths={1}
          anchorDirection={anchorDirection}
          isOutsideRange={isOutsideRange}
          renderDayContents={renderDay}
        />
      </div>
      {renderInputCheckInDate()}
    </div>
  );
};

export default ExperiencesDateSingleInput;
