import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import defaultimage from "images/sagra19.png";
import { HandlerTipologiaProps } from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import StayCardTypologies from "./partial/StayCardTypologies";

export interface StayCardProps {
  className?: string;
  ratioClass?: string;
  info?: any;
  data?: any;
  onClick?: (tipologia: HandlerTipologiaProps) => void;
  size?: "default" | "small";
}

export interface Etichette {
  id?: number;
  evento_id?: number;
  tipologia_id?: number;
  stato_id?: number;
  etichetta_stato?: string;
}

const StayCard: FC<StayCardProps> = ({
  size = "default",
  className = "",
  data,
  info,
  onClick,
  ratioClass,
}) => {

  const [typeEvent, setTypeEvento] = useState([]);
  const [tipologia, setTipologia] = useState<HandlerTipologiaProps>({});
  useEffect(() => {
    getTypeEvents();
  }, []);

  useEffect(() => {

    if (onClick) {
      if (tipologia != null) {
        onClick(tipologia);
      }
    }

  }, [tipologia]);

  const getTypeEvents = () => {
    fetch(`https://booking.jooba.it/types?id=${data.id}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then(async (response) => {
        setTypeEvento(response.data);
      })
      .catch((error) => console.log(error));
  };

  const renderChiusoBadges = (etichetteStati: any) => {
    if (etichetteStati && etichetteStati.length > 0) {
      return etichetteStati.map((item: Etichette) => {
        if (item.tipologia_id == null || item.tipologia_id == undefined) {
          return (
            <>
              {item?.stato_id == 3 && item.etichetta_stato != "" && item.etichetta_stato != null && (
                <Badge className="animate-pulse w-full py-2 mr-2 mt-2" name={item.etichetta_stato != "" ? item.etichetta_stato : ''} color="red" />
              )}
            </>
          );
        }
      });
    } else {
      return <Badge className="animate-pulse py-2 mr-2 mt-2" name="CHIUSO" color="red" />;
    }
  };

  const temp_data = data.data;
  let anno = temp_data?.slice(0, 4);
  let mese = temp_data?.slice(5, 7);
  let giorno = temp_data?.slice(8, 10);
  const data_finale = giorno + '/' + mese + '/' + anno;
  const temp_day = new Date(data.data || "").getDay();

  let day = "";

  switch (temp_day) {
    case 0:
      day = "Domenica";

      break;
    case 1:
      day = "Lunedì";

      break;
    case 2:
      day = "Martedì";

      break;
    case 3:
      day = "Mercoledì";

      break;
    case 4:
      day = "Giovedì";

      break;
    case 5:
      day = "Venerdì";

      break;
    case 6:
      day = "Sabato";

      break;
    default:
      break;
  }

  return (
    <div
  className={`group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow flex flex-col h-full ${className}`}
>
  {data && typeEvent && (
    <>
      <div className="relative w-full flex flex-col h-full">
        {data.image_copertina !== "" ? (
          <img src={`https://private.jooba.it/image/copertina/${data.image_copertina}/${data.id}`} alt="umoya" className="rounded-2xl" />
        ) : (
          <img src={defaultimage} alt="umoya" className="rounded-2xl" />
        )}
        <div className="flex flex-col p-4 flex-1 justify-between">
          <div className="flex flex-col space-y-2 flex-grow">
            <div className="flex flex-col">
              <div className="flex flex-row text-sm space-x-2">
                <h2 className={`font-medium capitalize ${size === "default" ? "text-lg" : "text-base"}`}>
                  <span className="line-clamp-2">{data.titolo}</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="flex flex-row space-x-4">
              <i className="las la-calendar text-lg"></i>
              <span className="text-sm text-neutral-500 dark:text-neutral-400">
                {day} {data_finale}
              </span>
            </div>
            <div className="flex flex-row space-x-4">
              <i className="las la-clock text-lg"></i>
              <span className="text-sm text-neutral-500 dark:text-neutral-400" style={{ display: "inline-block" }}>
                {data.ora?.slice(0, 5)}-{data.ora_fine?.slice(0, 5)}
              </span>
            </div>
            <div className="flex flex-col">
              {(data.aperto === 1 || data.aperto === 0 || data.aperto === 5) && (
                <div className="flex flex-wrap justify-center lg:justify-start">
                  {typeEvent.map((item: any, index: number) => (
                    <StayCardTypologies
                      key={index}
                      info={info}
                      item={item}
                      etichette_stati={data?.etichette_stati || []}
                      _setTipologia={setTipologia}
                    />
                  ))}
                </div>
              )}
              {data.aperto === 3 && <div className="flex flex-wrap justify-center lg:justify-start">{renderChiusoBadges(data.etichette_stati)}</div>}
            </div>
            <Link to={{ pathname: `/dettaglio/${data.id}`, state: { info: info } }} className="w-full mt-5">
              <ButtonPrimary className="w-full rounded-2xl">Scopri di più</ButtonPrimary>
            </Link>
          </div>
        </div>
      </div>
    </>
  )}
</div>

    // <div
    //   className={`group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow flex flex-col justify-between ${className}`}
    // >
    //   {data && typeEvent && (
    //     <>
    //       <div className="relative w-full">
    //         {data.image_copertina != "" ? (
    //           <img src={`https://private.jooba.it/image/copertina/${data.image_copertina}/${data.id}`} alt="umoya" className="rounded-2xl" />
    //         ) : (
    //           <img src={defaultimage} alt="umoya" className="rounded-2xl" />
    //         )}
    //         <div className="flex flex-1 flex-col p-4 justify-between">
    //           <div className="flex flex-col space-y-2">
    //             <div className="flex flex-col">
    //               <div className="flex flex-row text-sm space-x-2">
    //                 <h2 className={`font-medium capitalize ${size === "default" ? "text-lg" : "text-base"}`}>
    //                   <span className="line-clamp-2">{data.titolo}</span>
    //                 </h2>
    //               </div>
    //             </div>
    //           </div>
    //           <div className="flex flex-col">
    //             <div className="flex flex-row space-x-4">
    //               <i className="las la-calendar text-lg"></i>
    //               <span className="text-sm text-neutral-500 dark:text-neutral-400">
    //                 {day} {data_finale}
    //               </span>
    //             </div>
    //             <div className="flex flex-row space-x-4">
    //               <i className="las la-clock text-lg"></i>
    //               <span className="text-sm text-neutral-500 dark:text-neutral-400" style={{ display: "inline-block" }}>
    //                 {data.ora?.slice(0, 5)}-{data.ora_fine?.slice(0, 5)}
    //               </span>
    //             </div>
    //             <div className="flex flex-col">
    //               {(data.aperto == 1 || data.aperto == 0 || data.aperto == 5) && (
    //                 <>
    //                   <div className="flex flex-wrap justify-center lg:justify-start">
    //                     {typeEvent.map((item: any, index: number) => (
    //                       <StayCardTypologies
    //                         key={index}
    //                         info={info}
    //                         item={item}
    //                         etichette_stati={data?.etichette_stati || []}
    //                         _setTipologia={setTipologia}
    //                       />
    //                     ))}
    //                   </div>
    //                 </>
    //               )}
    //               {data.aperto == 3 && <div className="flex flex-wrap justify-center lg:justify-start">{renderChiusoBadges(data.etichette_stati)}</div>}
    //             </div>
    //             <Link to={{ pathname: `/dettaglio/${data.id}`, state: { info: info } }} className="w-full mt-5">
    //               <ButtonPrimary className="w-full rounded-2xl">Scopri di più</ButtonPrimary>
    //             </Link>
    //           </div>
    //         </div>
    //       </div>
    //     </>
    //   )}
    // </div>
  );
};

export default StayCard;
