import { Tab } from "@headlessui/react";
import { PencilAltIcon } from "@heroicons/react/outline";
import React, { FC, Fragment, useState, useEffect, ChangeEvent } from "react";
import visaPng from "images/vis.png";
import mastercardPng from "images/mastercard.svg";
import Input from "shared/Input/Input";
import Label from "components/Label/Label";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";
import StartRating from "components/StartRating/StartRating";
import NcModal from "shared/NcModal/NcModal";
import { useLocation, useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Checkbox from "shared/Checkbox/Checkbox";
import { Link } from "react-router-dom";
import Select from "shared/Select/Select";
import FlightCardNote from "components/FlightCard/FlightCardNote";
import PhoneInput from "shared/Input/PhoneInput";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import moment from "moment";

export interface CheckOutPageProps {
  className?: string;
  metodo?: string;
}

interface State {
  data_finale?: string;
  tiporistorante?: string;
  fasciaorariaselezionata?: string;
  adulti?: number;
  bambini?: number;
  animali?: boolean;
  tariffaAdulto?: string;
  tariffaBambino?: string;
  quota_una_tantum?: number;
  tipologia?: string
  paga_online?: number;
  paga_struttura?: number;
  val_pren?: string;
  stato?: string;
  area?: string;
  area_id?: number;
  note_generali?: string;
  title_note_generali?: string;
  title_note_token?: string;
  note_token?: string;
  accordion_bambini?: string;
  accordion_richiestadiprenotazione?: string;
  accordion_listadiattesa?: string;
  testo_accordion_bambini?: string;
  testo_accordion_richiestadiprenotazione?: string;
  testo_accordion_listadiattesa?: string;
  ta?: string;
  tb?: string;
  service_id?: number;
  stato_anagrafato_negativo?: string;
  stato_anagrafato_positivo?: string;
  stato_nuovo_cliente?: string;
  stato_bannato?: string;
  stato_tesserato?: string;
  animali_ammessi?: number;
  bambini_ammessi?: number;
}


const CheckOutPage: FC<CheckOutPageProps> = ({ className = "" }) => {

  const europeanPrefixes = [
    "+30", // Grecia
    "+31", // Paesi Bassi
    "+32", // Belgio
    "+33", // Francia
    "+34", // Spagna
    "+36", // Ungheria
    "+39", // Italia
    "+40", // Romania
    "+41", // Svizzera
    "+43", // Austria
    "+44", // Regno Unito
    "+45", // Danimarca
    "+46", // Svezia
    "+47", // Norvegia
    "+48", // Polonia
    "+49", // Germania
  ];

  const [selectedPrefix, setSelectedPrefix] = useState("+39");

  const handlePrefixChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedPrefix(event.target.value);
  };

  const handlePhoneNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    const numericValue = event.target.value.replace(/[^0-9]/g, "");
    setSearchTel(numericValue);
  };

  const [showFieldAnagrafica, setShowFieldAnagrafica] = useState(false);
  /* Rierca cliente per numero di telefono */
  const handleSearch = () => {

    if (telefonoSearch == "") {
      MySwal.fire({
        title: <p>Attenzione</p>,
        html: <p>Attenzione il campo telefono è vuoto!</p>,
        confirmButtonColor: "#aa7832a8",
        icon: "error",
        didOpen: () => {
        },
      }).then(() => {
      });
      return;
    }

    setClienteByTelefono(telefonoSearch);
  };

  function setClienteByTelefono(telefonoSearch: any) {

    fetch("https://booking.jooba.it/get_customer", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ 'telefono': telefonoSearch, 'prefisso': selectedPrefix }),
    })
      .then((response) => {
        return response.json();
      })
      .then(async (response) => {
        if (response != 0) {

          // Sezione dati
          setId(response.id);
          setNome(response.nome.slice(0, -5) + "*****");
          setCognome(response.cognome.slice(0, -5) + "*****");
          if (response.email == "") {
            setEmailMancante(true);
          } else {
            setEmailMancante(false);
            setEmail(response.email.slice(0, -15) + "*****");
          }
          setPrivacy(true);

          //Sezione stato
          if (response.abilitato == 0) {

            setClienteAnagrafato(false);
            setClienteTesserato(false);
            setClienteNotAbilitato(false);
            setStatoButtonFinish('');
            setStato(stato_prenotazione);

            MySwal.fire({
              title: <p>Attenzione</p>,
              html: <p>Non è abilitato il completamento della prenotazione!</p>,
              icon: "error",
              //footer: 'Copyright 2018',
              didOpen: () => {
                //MySwal.clickConfirm()
              }
            }).then(() => {
              //return MySwal.fire(<p>Shorthand works too</p>)
            })
            return;

          } else if (response.tesserato == 1) {

            setClienteAnagrafato(true);
            setClienteTesserato(true);
            setClienteNotAbilitato(true);

            setStatoButtonFinish(stato_tesserato);
            setStato(stato_prenotazione);

          } else if (response.abilitato == 1 && response.tesserato == 0) {

            setClienteAnagrafato(true);
            setClienteTesserato(false);
            setClienteNotAbilitato(true);

            if (response.val_media != null && response.val_media != "" && response.val_media != 0) {
              if (response.val_media >= val_pren) { //Anagrafato positivo

                setClienteAnagrafatoPositivo(true);
                setStatoButtonFinish(stato_anagrafato_positivo);
                setStato(stato_anagrafato_positivo);

              } else { //Anagrafato negativo

                setClienteAnagrafatoPositivo(false);
                setStatoButtonFinish(stato_anagrafato_negativo);
                setStato(stato_anagrafato_negativo);

              }

            } else { //Come se fosse un nuovo cliente

              setClienteAnagrafatoPositivo(false);
              setStatoButtonFinish(stato_nuovo_cliente);
              setStato(stato_nuovo_cliente);

            }

          }

        } else {

          //Sezione dati nuovo cliente
          setId('');
          setNome('');
          setCognome('');
          setPrivacy(false);
          setEmailMancante(true);

          setNuovoCliente(true);
          setClienteAnagrafato(false);
          setClienteTesserato(false);

          if (stato_prenotazione == "APERTO") {

            setClienteAnagrafato(false);
            setClienteTesserato(false);
            setClienteNotAbilitato(true);

            setStatoButtonFinish(stato_nuovo_cliente);
            setStato(stato_nuovo_cliente);

            if (paga_online == 1 && paga_struttura == 0) {
              handleClick(1)
            } else if (paga_online == 0 && paga_struttura == 1) {
              handleClick(2)
            }

            if (stato_nuovo_cliente == "CHIUSO") {
              setStato(stato_prenotazione);
              setStatoButtonFinish('');

              MySwal.fire({
                title: <p>Attenzione</p>,
                html: <p>Siamo spiacenti ma il ristorante è impegnato per un evento privato!</p>,
                confirmButtonColor: "#aa7832a8",
                icon: "error",
                didOpen: () => {
                  //MySwal.clickConfirm()
                }
              }).then(() => {
                //return MySwal.fire(<p>Shorthand works too</p>)
              })
            }

          } else setStatoButtonFinish(stato_prenotazione);

        }
        setShowFieldAnagrafica(true);
      })
      .catch((err) => console.log(err));
  }

  const [prenotazione, setPrenotazione] = useState<State>();
  const location = useLocation<State>();

  const data_finale = prenotazione?.data_finale;
  const tiporistorante = prenotazione?.tiporistorante;
  const fasciaorariaselezionata = prenotazione?.fasciaorariaselezionata;
  const adulti = prenotazione?.adulti || 0;
  const bambini = prenotazione?.bambini || 0;
  const animali = prenotazione?.animali;
  const tariffaAdulto = prenotazione?.tariffaAdulto || '0';
  const tariffaBambino = prenotazione?.tariffaBambino || '0';
  const quota_una_tantum = prenotazione?.quota_una_tantum || 0;
  const area = prenotazione?.area || '';
  const area_id = prenotazione?.area_id || 0;
  const note_generali = prenotazione?.note_generali || '';
  const note_token = prenotazione?.note_token || '';
  const title_note_token = prenotazione?.title_note_token || 'Note Formula Token';
  const title_note_generali = prenotazione?.title_note_generali || 'Note Generali';
  const ta = prenotazione?.ta || '';
  const tb = prenotazione?.tb || '';
  const tipologia_id = prenotazione?.service_id;
  const accordion_bambini = prenotazione?.accordion_bambini;
  const accordion_richiestadiprenotazione = prenotazione?.accordion_richiestadiprenotazione;
  const accordion_listadiattesa = prenotazione?.accordion_listadiattesa;
  const testo_accordion_bambini = prenotazione?.testo_accordion_bambini;
  const testo_accordion_richiestadiprenotazione = prenotazione?.testo_accordion_richiestadiprenotazione;
  const testo_accordion_listadiattesa = prenotazione?.testo_accordion_listadiattesa;

  const stato_anagrafato_negativo = prenotazione?.stato_anagrafato_negativo || '';
  const stato_anagrafato_positivo = prenotazione?.stato_anagrafato_positivo || '';
  const stato_nuovo_cliente = prenotazione?.stato_nuovo_cliente || '';
  const stato_bannato = prenotazione?.stato_bannato || '';
  const stato_tesserato = prenotazione?.stato_tesserato || '';

  const tipologia = prenotazione?.tipologia || '';
  const paga_online = prenotazione?.paga_online;
  const paga_struttura = prenotazione?.paga_struttura;
  const val_pren = parseFloat(prenotazione?.val_pren || '');
  const stato_prenotazione = prenotazione?.stato || '';

  const animali_ammessi = prenotazione?.animali_ammessi;
  const bambini_ammessi = prenotazione?.bambini_ammessi;


  const [msg, setMsg] = useState('');
  const [id, setId] = useState('');
  const [nome, setNome] = useState('');
  const [cognome, setCognome] = useState('');
  const [emailmancante, setEmailMancante] = useState(false);
  const [telefono, setTel] = useState('');
  const [email, setEmail] = useState('');
  const [telefonoSearch, setSearchTel] = useState('');
  const [cond3, setPrivacy] = useState(false);
  const [cond1, setCond1] = useState(false);
  const [agent_id, setAgente] = useState('');
  const [selectedPayPal, setPayPal] = useState(false);
  const [selectedStruttura, setStruttura] = useState(false);
  const [metpagamento, setMetodo] = useState(0);

  const [stato, setStato] = useState('');
  const [statoFinale, setStatoButtonFinish] = useState('');
  const [nuovoCliente, setNuovoCliente] = useState(false);
  const [clienteAnagrafato, setClienteAnagrafato] = useState(false);
  const [clienteTesserato, setClienteTesserato] = useState(false);
  const [clienteAbilitato, setClienteNotAbilitato] = useState(true);
  const [clienteAnagrafatoPositivo, setClienteAnagrafatoPositivo] = useState(false);

  const tot_adulti_struttura = (parseFloat(tariffaAdulto || "0") * (adulti || 0)).toFixed(2);
  const tot_bambini_struttura = (parseFloat(tariffaBambino || "0") * (bambini || 0)).toFixed(2);
  const totalePrice = (parseFloat(tariffaAdulto) * adulti + parseFloat(tariffaBambino) * bambini + quota_una_tantum).toFixed(2);
  const [lastActionTimestamp, setLastActionTimestamp] = useState(0);

  var history = useHistory();
  const MySwal = withReactContent(Swal)

  useEffect(() => {

    setPrenotazione(location.state);

  }, [location, metpagamento]);


  const handleClick = (metodo: number) => {

    setMetodo(metodo);
    if (metodo == 1) {
      setPayPal(true);
      setStruttura(false);
    } else if (metodo == 2) {
      setPayPal(false);
      setStruttura(true);
    } else {
      setPayPal(false);
      setStruttura(false);
    }

  }

  useEffect(() => {

    if (stato == 'RICHIESTA') {
      setMsg('Si sta effettuando una richiesta di prenotazione, non equivale ad una prenotazione!');
    } else if (stato == 'ATTESA') {
      setMsg('Si sta effettuando l\'inserimento in lista di attesa!');
    } else if (stato == 'APERTO') {
      setMsg('');
    }

    if (statoFinale == 'RICHIESTA') {
      setMsg('Si sta effettuando una richiesta di prenotazione, non equivale ad una prenotazione!');
    } else if (statoFinale == 'ATTESA') {
      setMsg('Si sta effettuando l\'inserimento in lista di attesa!');
    } else if (statoFinale == 'APERTO') {
      setMsg('');
    }


  }, [stato, statoFinale]);


  useEffect(() => {

    if (telefono.trim().length >= 9) {

      const timer = setTimeout(() => {
        const currentTime = Date.now();

        if (currentTime - lastActionTimestamp >= 500) {

          handleSearch();

        }

      }, 500);

      setLastActionTimestamp(Date.now());

      return () => clearTimeout(timer);

    } else if (telefono.trim().length < 9) {

      const timer = setTimeout(() => {
        const currentTime = Date.now();

        if (currentTime - lastActionTimestamp >= 500) {
          setStato(stato_prenotazione);
          setStatoButtonFinish('');
          setId('');
          setNome('');
          setCognome('');
          setEmail('');
          setPrivacy(false);
          setEmailMancante(true);
        }

      }, 500);

      setLastActionTimestamp(Date.now());

      return () => clearTimeout(timer);

    }

  }, [telefono, lastActionTimestamp]);

  useEffect(() => {

    setStatoFinale();

  }, [clienteAnagrafato]);

  // const handleSearch = (telefono: string) => {

  //   const jsonPost = {
  //     telefonoSearch: telefono
  //   }

  //   fetch("https://booking.jooba.it/get_customer", {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify(
  //       jsonPost
  //     )
  //   })
  //     .then((response) => {
  //       return response.json();
  //     }).then(async (response) => {

  //       if (response != 0) {

  //         // Sezione dati
  //         setId(response.id);
  //         setNome(response.nome.slice(0, -5) + "*****");
  //         setCognome(response.cognome.slice(0, -5) + "*****");
  //         if (response.email == "") {
  //           setEmailMancante(true);
  //         } else {
  //           setEmailMancante(false);
  //           setEmail(response.email.slice(0, -15) + "*****");
  //         }
  //         setPrivacy(true);

  //         //Sezione stato
  //         if (response.abilitato == 0) {

  //           setClienteAnagrafato(false);
  //           setClienteTesserato(false);
  //           setClienteNotAbilitato(false);
  //           setStatoButtonFinish('');
  //           setStato(stato_prenotazione);

  //           MySwal.fire({
  //             title: <p>Attenzione</p>,
  //             html: <p>Non è abilitato il completamento della prenotazione!</p>,
  //             icon: "error",
  //             //footer: 'Copyright 2018',
  //             didOpen: () => {
  //               //MySwal.clickConfirm()
  //             }
  //           }).then(() => {
  //             //return MySwal.fire(<p>Shorthand works too</p>)
  //           })
  //           return;

  //         } else if (response.tesserato == 1) {

  //           setClienteAnagrafato(true);
  //           setClienteTesserato(true);
  //           setClienteNotAbilitato(true);

  //           setStatoButtonFinish(stato_tesserato);
  //           setStato(stato_prenotazione);

  //         } else if (response.abilitato == 1 && response.tesserato == 0) {

  //           setClienteAnagrafato(true);
  //           setClienteTesserato(false);
  //           setClienteNotAbilitato(true);

  //           if (response.val_media != null && response.val_media != "" && response.val_media != 0) {
  //             if (response.val_media >= val_pren) { //Anagrafato positivo

  //               setClienteAnagrafatoPositivo(true);
  //               setStatoButtonFinish(stato_anagrafato_positivo);
  //               setStato(stato_anagrafato_positivo);

  //             } else { //Anagrafato negativo

  //               setClienteAnagrafatoPositivo(false);
  //               setStatoButtonFinish(stato_anagrafato_negativo);
  //               setStato(stato_anagrafato_negativo);

  //             }

  //           } else { //Come se fosse un nuovo cliente

  //             setClienteAnagrafatoPositivo(false);
  //             setStatoButtonFinish(stato_nuovo_cliente);
  //             setStato(stato_nuovo_cliente);

  //           }

  //         }

  //       } else {

  //         //Sezione dati nuovo cliente
  //         setId('');
  //         setNome('');
  //         setCognome('');
  //         setPrivacy(false);
  //         setEmailMancante(true);

  //         setNuovoCliente(true);
  //         setClienteAnagrafato(false);
  //         setClienteTesserato(false);

  //         if (stato_prenotazione == "APERTO") {

  //           setClienteAnagrafato(false);
  //           setClienteTesserato(false);
  //           setClienteNotAbilitato(true);

  //           setStatoButtonFinish(stato_nuovo_cliente);
  //           setStato(stato_nuovo_cliente);

  //           if (paga_online == 1 && paga_struttura == 0) {
  //             handleClick(1)
  //           } else if (paga_online == 0 && paga_struttura == 1) {
  //             handleClick(2)
  //           }

  //           if (stato_nuovo_cliente == "CHIUSO") {
  //             setStato(stato_prenotazione);
  //             setStatoButtonFinish('');

  //             MySwal.fire({
  //               title: <p>Attenzione</p>,
  //               html: <p>Siamo spiacenti ma il ristorante è impegnato per un evento privato!</p>,
  //               confirmButtonColor: "#aa7832a8",
  //               icon: "error",
  //               didOpen: () => {
  //                 //MySwal.clickConfirm()
  //               }
  //             }).then(() => {
  //               //return MySwal.fire(<p>Shorthand works too</p>)
  //             })
  //           }

  //         } else setStatoButtonFinish(stato_prenotazione);

  //       }

  //     }).catch(err => console.log(err));
  // }

  const setStatoFinale = () => {

    if (clienteTesserato === true) {

      if (stato == "APERTO") {

        if (paga_online == 1 && paga_struttura == 0) {
          handleClick(1)
        } else if (paga_online == 0 && paga_struttura == 1) {
          handleClick(2)
        }

        if (stato_tesserato == "CHIUSO") {

          setStato(stato_prenotazione);
          setStatoButtonFinish('');
          MySwal.fire({
            title: <p>Attenzione</p>,
            html: <p>Siamo spiacenti,la tipologia di servizio selezionata è Sold-Out</p>,
            confirmButtonColor: "#aa7832a8",
            icon: "error",
            didOpen: () => {
              //MySwal.clickConfirm()
            }
          }).then(() => {
            //return MySwal.fire(<p>Shorthand works too</p>)
          })
        }
        return;
      } else setStatoButtonFinish(stato_prenotazione);

    } else if (clienteAnagrafato === true) {

      if (clienteAnagrafatoPositivo === true) {

        if (stato == "APERTO") {

          if (paga_online == 1 && paga_struttura == 0) {
            handleClick(1)
          } else if (paga_online == 0 && paga_struttura == 1) {
            handleClick(2)
          }

          if (stato_anagrafato_positivo == "CHIUSO") {

            setStato(stato_prenotazione);
            setStatoButtonFinish('');

            MySwal.fire({
              title: <p>Attenzione</p>,
              html: <p>Siamo spiacenti,la tipologia di servizio selezionata è Sold-Out</p>,
              confirmButtonColor: "#aa7832a8",
              icon: "error",
              didOpen: () => {
                //MySwal.clickConfirm()
              }
            }).then(() => {
              //return MySwal.fire(<p>Shorthand works too</p>)
            })
          }

        } else setStatoButtonFinish(stato_prenotazione);

      } else {

        if (stato == "APERTO") {

          if (paga_online == 1 && paga_struttura == 0) {
            handleClick(1)
          } else if (paga_online == 0 && paga_struttura == 1) {
            handleClick(2)
          }


          if (stato_anagrafato_negativo == "CHIUSO") {

            setStato(stato_prenotazione);
            setStatoButtonFinish('');

            MySwal.fire({
              title: <p>Attenzione</p>,
              html: <p>Siamo spiacenti,la tipologia di servizio selezionata è Sold-Out</p>,
              confirmButtonColor: "#aa7832a8",
              icon: "error",
              didOpen: () => {
                //MySwal.clickConfirm()
              }
            }).then(() => {
              //return MySwal.fire(<p>Shorthand works too</p>)
            })
          }
        } else setStatoButtonFinish(stato_prenotazione);

      }
    }
  }

  const handleSubmit = (pagamento: string) => {

    if (nome == '') {
      MySwal.fire({
        title: <p>Attenzione</p>,
        html: <p>Il campo nome è obbligatorio</p>,
        confirmButtonColor: "#aa7832a8",
        icon: "error",
        //footer: 'Copyright 2018',
        didOpen: () => {
          //MySwal.clickConfirm()
        }
      }).then(() => {
        //return MySwal.fire(<p>Shorthand works too</p>)
      })
      return;

    } else if (cognome == '') {
      MySwal.fire({
        title: <p>Attenzione</p>,
        html: <p>Il cognome è obbligatorio</p>,
        confirmButtonColor: "#aa7832a8",
        icon: "error",
        //footer: 'Copyright 2018',
        didOpen: () => {
          //MySwal.clickConfirm()
        }
      }).then(() => {
        //return MySwal.fire(<p>Shorthand works too</p>)
      })
      return;
    } else if (email == '') {
      MySwal.fire({
        title: <p>Attenzione</p>,
        html: <p>Il email è obbligatorio</p>,
        icon: "error",
        //footer: 'Copyright 2018',
        didOpen: () => {
          //MySwal.clickConfirm()
        }
      }).then(() => {
        //return MySwal.fire(<p>Shorthand works too</p>)
      })
      return;
    } else if (telefonoSearch == '') {
      MySwal.fire({
        title: <p>Attenzione</p>,
        html: <p>Il telefono è obbligatorio</p>,
        confirmButtonColor: "#aa7832a8",
        icon: "error",
        //footer: 'Copyright 2018',
        didOpen: () => {
          //MySwal.clickConfirm()
        }
      }).then(() => {
        //return MySwal.fire(<p>Shorthand works too</p>)
      })
      return;
    } else if (!(/^[a-zA-Z0-9\.\_\-]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email)) && id == '') {
      MySwal.fire({
        title: <p>Attenzione</p>,
        html: <p>Il campo email non è valido</p>,
        icon: "error",
        //footer: 'Copyright 2018',
        didOpen: () => {
          //MySwal.clickConfirm()
        }
      }).then(() => {
        //return MySwal.fire(<p>Shorthand works too</p>)
      })
      return;
    } else if (!(/[0-9]/.test(telefonoSearch))) {
      MySwal.fire({
        title: <p>Attenzione</p>,
        html: <p>Il campo telefono non è valido</p>,
        confirmButtonColor: "#aa7832a8",
        icon: "error",
        //footer: 'Copyright 2018',
        didOpen: () => {
          //MySwal.clickConfirm()
        }
      }).then(() => {
        //return MySwal.fire(<p>Shorthand works too</p>)
      })
      return;
    } else if (telefonoSearch.length < 9) {
      MySwal.fire({
        title: <p>Attenzione</p>,
        html: <p>Il campo telefono non è valido</p>,
        confirmButtonColor: "#aa7832a8",
        icon: "error",
        //footer: 'Copyright 2018',
        didOpen: () => {
          //MySwal.clickConfirm()
        }
      }).then(() => {
        //return MySwal.fire(<p>Shorthand works too</p>)
      })
      return;
    } else if (!(/^[a-zA-Z0-9\.\_\-]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email)) && emailmancante === true) {
      MySwal.fire({
        title: <p>Attenzione</p>,
        html: <p>Il campo email non è valido</p>,
        icon: "error",
        //footer: 'Copyright 2018',
        didOpen: () => {
          //MySwal.clickConfirm()
        }
      }).then(() => {
        //return MySwal.fire(<p>Shorthand works too</p>)
      })
      return;
    }
    if (cond3 == false) {
      MySwal.fire({
        title: <p>Attenzione</p>,
        html: <p>Bisogna necessariamente accettare Privacy & Cookies Policy</p>,
        confirmButtonColor: "#aa7832a8",
        icon: "error",
        //footer: 'Copyright 2018',
        didOpen: () => {
          //MySwal.clickConfirm()
        }
      }).then(() => {
        //return MySwal.fire(<p>Shorthand works too</p>)
      })
      return;
    }

    const jsonPost = {
      customer_id: id,
      tipologia,
      fascia_oraria: fasciaorariaselezionata,
      tipo_risto: tiporistorante,
      nome,
      cognome,
      telefono: telefonoSearch,
      data: data_finale,
      email,
      service_id: tipologia_id,
      adulti,
      bambini,
      pagamento: pagamento.toUpperCase(),
      stato: statoFinale,
      cond1,
      cond3,
      agent_id,
      area,
      area_id,
      totale: totalePrice,
      animali: (animali === true) ? 1 : 0
    }

    fetch("https://booking.jooba.it/store_services", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(
        jsonPost
      )
    }).then((response) => response.json())
      .then(async (response) => {
        if (response.res == 1) {
          //prenotazione creata
          if (response.ticket != '') {
            let link = response.ticket;
            MySwal.fire({
              title: <p>Grazie</p>,
              html: <p>{response.msg}</p>,
              icon: "success",
              confirmButtonColor: "#aa7832a8",
              confirmButtonText: '<a href="' + link + '" target="_blank">Scarica il ticket!</a>',
              didOpen: () => {
                //MySwal.clickConfirm()

              }
            }).then((result) => {
              if (result.isConfirmed) {
                history.push('/');
              }
            })

          } else {
            if (response.url != '') {
              window.location.href = response.url;
            } else {
              MySwal.fire({
                title: <p>Grazie</p>,
                html: <p>{response.msg}</p>,
                confirmButtonColor: "#aa7832a8",
                icon: "success",
                //footer: 'Copyright 2018',
                didOpen: () => {
                  //MySwal.clickConfirm()
                }
              }).then(() => {
                history.push('/');
              })
            }
          }

        } else if (response == -1) {
          MySwal.fire({
            title: <p>Attenzione</p>,
            html: <p>Risulta essere presente già una prenotazione a tuo carico!</p>,
            icon: "warning",
            confirmButtonColor: "#aa7832a8",
            footer: 'Se non trovi l\'email con il ticket, controlla nella SPAM!',
            didOpen: () => {
              //MySwal.clickConfirm()
            }
          }).then(() => {
            //return MySwal.fire(<p>Shorthand works too</p>)
            history.push('/');
          })

        }
      })
      .catch(err => console.log(err));
  }

  const richiestaCliente = () => {

    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8 mb-10">
        <div>
          <span className="text-neutral-400">Inserisci il numero di telefono</span>
          <div className="flex space-x-5 mt-4 mb-4">
            <div className="flex-auto w-60 space-y-1">
              <PhoneInput
                prefixOptions={europeanPrefixes}
                selectedPrefix={selectedPrefix}
                phoneNumber={telefonoSearch}
                onPrefixChange={handlePrefixChange}
                onPhoneNumberChange={handlePhoneNumberChange}
              />
            </div>
          </div>
          <div className="mb-4">
            <ButtonPrimary className="w-full" sizeClass="py-2 px-12" onClick={() => handleSearch()}>
              Carica dati
            </ButtonPrimary>
          </div>
        </div>
        {showFieldAnagrafica &&
          <div>
            <hr className="mt-4 mb-4" />
            <div className="flex space-x-5">
              <div className="flex-1 space-y-1">
                <Label>Nome</Label>
                <Input type={"text"} value={nome} onInput={(e) => setNome((e.target as HTMLInputElement).value)} />
              </div>
              <div className="flex-1 space-y-1">
                <Label>Cognome</Label>
                <Input type={"text"} value={cognome} onInput={(e) => setCognome((e.target as HTMLInputElement).value)} />
              </div>
            </div>
            <div className="flex space-x-5 mt-4 mb-4">
              <div className="flex-1 space-y-1">
                <Label>Email</Label>
                <Input type={"email"} value={email} onInput={(e) => setEmail((e.target as HTMLInputElement).value)} />
              </div>
            </div>

            <div className="flex space-x-5 mt-6">
              <Checkbox
                className="w-full"
                onChange={(value) => {
                  setPrivacy(true);
                }}
                checked={cond3}
                label={
                  <div>
                    <span>Ho letto e accetto la</span>
                    <Link className="text-primary-6000" to={"/privacy-policy"}>
                      {" "}
                      Privacy & Cookies Policy
                    </Link>
                  </div>
                }
                name="cond3"
              />
            </div>
            <div className="flex space-x-5 mt-6">
              <Checkbox
                className="w-full"
                onChange={(value) => {
                  if (cond1 == false) {
                    setCond1(true);
                  } else setCond1(false);
                }}
                checked={cond1}
                label={
                  <div>
                    <span>Voglio ricevere aggiornamenti sugli eventi Umoya</span>
                  </div>
                }
                name="cond1"
              />
            </div>
          </div>
        }
        <div className="flex flex-col space-y-3">
          <hr className="py-2" />

          {(!clienteAnagrafato && !nuovoCliente) ||
            (statoFinale == "" && (
              <div className="pt-4">
                <ButtonSecondary
                  className="w-full"
                  onClick={() => {
                    return;
                  }}
                >
                  Per continuare compila i tuoi dati
                </ButtonSecondary>
              </div>
            ))}


          {statoFinale == 'APERTO' && clienteAbilitato === true && (
            sezioneButtonPagamento()
          )}
          {statoFinale == 'RICHIESTA' && clienteAbilitato === true && (
            sezioneButtonPagamentoRichiesta()
          )}
          {(statoFinale == 'ATTESA' && clienteAbilitato === true) && (
            sezioneButtonPagamentoLista()
          )}
        </div>
      </div>
    );
  };

  const renderSidebar = () => {

    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8 mb-10">

        <div className="flex flex-col space-y-2">
          <h3 className="text-2xl font-semibold text-primary-6000">Dettaglio Prezzi</h3>
          <hr />
          <div className="space-y-4">
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              {stato == "RICHIESTA" && <span>Stai effettuando una richiesta di prenotazione per: </span>}
              {stato == "ATTESA" && <span>Ti stai inserendo in una lista di attesa per: </span>}
              {stato == "APERTO" && <span>Stai effettuando una prenotazione per: </span>}
            </div>
            <div className="flex justify-center text-neutral-6000 dark:text-neutral-300">
              {tipologia == "RISTORANTE"
                ?
                <span className="font-bold" style={{ fontSize: 20 }}>{tiporistorante} - fascia oraria: {fasciaorariaselezionata}</span>
                :
                <span className="font-bold" style={{ fontSize: 20 }}>{tipologia}</span>
              }
            </div>
            <div className="flex justify-center text-neutral-6000 dark:text-neutral-300">
              <span className="font-bold" style={{ fontSize: 20, textTransform: 'capitalize' }}>{moment(data_finale, 'DD/MM/YYYY').format('dddd')} {data_finale}</span>
            </div>
          </div>

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

          <div className="flex items-center" style={{ justifyContent: "space-between" }}>
            <div>
              <i className="las la-male text-lg mr-2"></i>
              <span className="text-sm text-neutral-500">Adulti</span>
            </div>
            <div className="flex flex-col ml-auto">
              <span className="text-sm text-neutral-500">
                {(tipologia != "RISTORANTE" && tipologia != "APERITIVO")
                  ? "x" + adulti + " €" + parseFloat(tariffaAdulto).toFixed(2)
                  : adulti
                }
              </span>
            </div>
          </div>

          {bambini_ammessi == 1 ? (
            <>
              <div className="flex items-center" style={{ justifyContent: "space-between" }}>
                <div>
                  <i className="las la-child text-lg mr-2"></i>
                  <span className="text-sm text-neutral-500">Bambini Da 4 a 12 anni</span>
                </div>
                <div className="flex flex-col ml-auto">
                  <span className="text-sm text-neutral-500">
                    {bambini > 0 && (tipologia != "RISTORANTE" && tipologia != "APERITIVO")
                      ? `x${bambini} €${tariffaBambino}`
                      : bambini
                    }
                  </span>
                </div>
              </div>
              <div className="flex items-center" style={{ justifyContent: "space-between" }}>
                <div>
                  <i className="las la-baby text-lg mr-2"></i>
                  <span className="text-sm text-neutral-500">Bambini da 0 a 3 anni</span>
                </div>
                <div className="flex flex-col ml-auto">
                  <span className="text-sm text-neutral-500">Gratis</span>
                </div>
              </div>
            </>
          ) : (
            <div className="flex items-center" style={{ justifyContent: "space-between" }}>
              <div>
                <i className="las la-child text-lg mr-2"></i>
                <span className="text-sm text-neutral-500">Bambini</span>
              </div>
              <div className="flex flex-col ml-auto">
                <span className="text-sm text-neutral-500">Non ammessi</span>
              </div>
            </div>
          )}
          {animali_ammessi == 1 ? (
            <div className="flex items-center" style={{ justifyContent: "space-between" }}>
              <div>
                <i className="las la-dog text-lg mr-2"></i>
                <span className="text-sm text-neutral-500">Animali</span>
              </div>
              <div className="flex flex-col ml-auto">
                <span className="text-sm text-neutral-500">{animali ? "Si" : "No"}</span>
              </div>
            </div>
          ) : (
            <div className="flex items-center" style={{ justifyContent: "space-between" }}>
              <div>
                <i className="las la-dog text-lg mr-2"></i>
                <span className="text-sm text-neutral-500">Animali</span>
              </div>
              <div className="flex flex-col ml-auto">
                <span className="text-sm text-neutral-500">Non ammessi</span>
              </div>
            </div>
          )}

          {quota_una_tantum > 0 &&
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span>Prenotazione</span>  <span>€ {(quota_una_tantum).toFixed(2)}</span>
            </div>
          }
          {area != "" &&
            <div className="flex items-center" style={{ justifyContent: "space-between" }}>
              <div>
                <i className="las la-info text-lg mr-2"></i>
                <span className="text-sm text-neutral-500">Area assegnata</span>
              </div>
              <div className="flex flex-col ml-auto">
                <span className="text-sm text-neutral-500 font-bold">{area}</span>
              </div>
            </div>
          }
          {note_generali != null && note_generali != "" &&
            <>
              <div>
                <FlightCardNote data={{ titolo: title_note_generali, descrizione: note_generali.replace('$TA', ta).replace('$TB', tb) }} />
              </div>
            </>
          }
          {note_token != "" &&
            <>
              <div>
                <FlightCardNote data={{ titolo: title_note_token, descrizione: note_token }} />
              </div>
            </>
          }
          {(stato == 'RICHIESTA' && ((accordion_richiestadiprenotazione != "" && accordion_richiestadiprenotazione != null) && (testo_accordion_richiestadiprenotazione != "" && testo_accordion_richiestadiprenotazione != null))) &&
            <>
              <div>
                <FlightCardNote data={{ titolo: accordion_richiestadiprenotazione, descrizione: testo_accordion_richiestadiprenotazione }} />
              </div>
            </>
          }
          {(stato == "ATTESA" && ((accordion_listadiattesa != "" && accordion_listadiattesa != null) && (testo_accordion_listadiattesa != "" && testo_accordion_listadiattesa != null))) &&
            <>
              <div>
                <FlightCardNote data={{ titolo: accordion_listadiattesa, descrizione: testo_accordion_listadiattesa }} />
              </div>
            </>
          }
          {((accordion_bambini != "" && accordion_bambini != null) && (testo_accordion_bambini != "" && testo_accordion_bambini != null)) &&
            <>
              <div>
                <FlightCardNote data={{ titolo: accordion_bambini, descrizione: testo_accordion_bambini }} />
              </div>
            </>
          }

          <div>
            <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span><b>{msg}</b></span>
            </div>
          </div>

        </div>

      </div>
    );
  };

  const sezioneButtonPagamento = () => {
    return (
      <div className="pt-4">
        <ButtonPrimary
          className="w-full bg-green-700 hover:bg-primary-700"
          onClick={() => {
            handleSubmit("Struttura");
          }}
        >
          Conferma
        </ButtonPrimary>
      </div>
    );
  };

  const sezioneButtonPagamentoRichiesta = () => {

    return (
      <div className="pt-4">
        <div className="flex justify-between text-neutral-6000 dark:text-neutral-300 text-center mb-2">
          <span className="text-sm" style={{ color: "red", fontStyle: "italic" }}>
            <b>Richiesta di prenotazione</b>
          </span>
        </div>
        <ButtonPrimary
          className="w-full"
          onClick={() => {
            handleSubmit("Richiesta");
          }}
        >
          Invia richiesta
        </ButtonPrimary>
      </div>
    );
  };

  const sezioneButtonPagamentoLista = () => {

    return (
      <div className="pt-4">
        <div className="flex justify-between text-neutral-6000 dark:text-neutral-300 text-center mb-2">
          <span className="text-sm" style={{ color: "red", fontStyle: "italic" }}>
            <b>Conferma</b>
          </span>
        </div>
        <ButtonPrimary className="w-full bg-green-700 hover:bg-primary-700" onClick={() => { handleSubmit('Attesa') }}>Inserisciti in lista di attesa</ButtonPrimary>
      </div>
    );
  };

  return (
    <div className={`nc-CheckOutPage ${className}`} data-nc-id="CheckOutPage">
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">

        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10">
          {richiestaCliente()}
        </div>
        {data_finale &&
          <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10">{renderSidebar()}</div>
        }
      </main>
    </div>
  );
};

export default CheckOutPage;