import React, { FC, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import moment from "moment";
import balneazione from "images/umoya/balneazione.jpg";
import ristorante from "images/umoya/ristorante.jpg";
import aperitivo from "images/umoya/aperitivo.jpg";

export interface StayCardMirkoProps {
  className?: string;
  ratioClass?: string;
  data?: any;
  info?: any;
  size?: "default" | "small";
}

const StayCardMirko: FC<StayCardMirkoProps> = ({
  size = "default",
  className = "",
  data,
  info,
  ratioClass,
}) => {

  const formattedDate = moment(data.dateValue).format('YYYY-MM-DD');
  const dataRistorante = moment(data.dateValue).format('DD/MM/YYYY');
  const [restaurantData, setRestaurantData] = useState<any>(null);
  const [ristoPranzoCena, setRistoPranzoCena] = useState('');
  const history = useHistory();

  useEffect(() => {

    if (ristoPranzoCena != '') {
      history.push({
        pathname: `/servizi`,
        state: { info: info, id: data.id, dateValue: formattedDate, ristoPranzoCena: ristoPranzoCena }
      });
    }

  }, [ristoPranzoCena]);

  useEffect(() => {

    if (data.title === 'RISTORANTE') {
      fetch(`https://booking.jooba.it/getTariffeService?data=${dataRistorante}&service=RISTORANTE`, {
        method: 'GET'
      })
        .then((response) => response.json())
        .then((response) => {
          setRestaurantData(response);
        })
        .catch((error) => console.log(error));
    }

  }, [data.title, dataRistorante]);

  return (
    <div className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow ${className}`} data-nc-id="StayCard" >
      {data &&
        <div className="flex flex-col" style={{ justifyContent: 'space-between', height: '100%' }}>
          <div className="relative w-full">
            {data.title == 'SOLARIUM' &&
              <img src={balneazione} alt="umoya" className="rounded-2xl" />
            }
            {data.title == 'RISTORANTE' &&
              <img src={ristorante} alt="umoya" className="rounded-2xl" />
            }
            {data.title == 'APERITIVO' &&
              <img src={aperitivo} alt="umoya" className="rounded-2xl" />
            }
             <h2 className={`font-medium capitalize text-lg p-4`}>
                <span className="line-clamp-2">{data.title}</span>
              </h2>
          </div>
          <div className="flex flex-col p-5">
     
            {restaurantData && (
              <>
                {restaurantData.disponibile_pranzo === 'SI' && (
                  <button
                    onClick={() => setRistoPranzoCena('PRANZO')}
                    className="w-full mt-2 w-full text-green-800 bg-green-100 animate-pulse nc-Badge px-2.5 py-2 rounded-full font-medium transition-colors hover:bg-green-700 hover:text-white duration-300 text-sm"
                  >
                    <i className="la la-check-circle mr-4 la-lg"></i>Pranzo
                  </button>
                )}
                {restaurantData.disponibile_cena === 'SI' && (
                  <button
                    onClick={() => setRistoPranzoCena('CENA')}
                    className="w-full mt-2 w-full text-green-800 bg-green-100 animate-pulse nc-Badge px-2.5 py-2 rounded-full font-medium transition-colors hover:bg-green-700 hover:text-white duration-300 text-sm"
                  >
                    <i className="la la-check-circle mr-4 la-lg"></i>Cena
                  </button>
                )}
              </>
            )}
          </div>
          <div className="flex flex-col p-5">
            <Link to={{ pathname: `/servizi`, state: { info: info, id: data.id, dateValue: formattedDate, ristoPranzoCena: ristoPranzoCena } }} className="w-full">
              <ButtonPrimary className="w-full rounded-2xl">Scopri di più</ButtonPrimary>
            </Link>
          </div>
        </div>
      }
    </div>
  );
};

export default StayCardMirko;
