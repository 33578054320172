import React, { FC } from "react";
import rightImg from "images/umoya.png";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHeroStatoPrenotazione from "./SectionHeroStatoPrenotazione";

export interface PageVerificaStatoPrenotazioneProps {
  className?: string;
}

const PageVerificaStatoPrenotazione: FC<PageVerificaStatoPrenotazioneProps> = ({ className = "" }) => {

  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>Umoya - Stato prenotazione</title>
      </Helmet>

      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
            <SectionHeroStatoPrenotazione
              rightImg={rightImg}
              heading="Controlla la tua prenotazione!"
              btnText="Verifica lo stato"
              subHeading="Inserisci il numero di telefono associato alla prenotazione:"
            />
      </div>
    </div>
  );
};

export default PageVerificaStatoPrenotazione;
