import React, { Fragment, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import { FC } from "react";
import ClearDataButton from "./ClearDataButton";
import { State } from "containers/ListingDetailPage/ListingExperiencesDetailPage";
import { useLocation } from "react-router-dom";


export interface GuestsInputPropsBambini {
  defaultValue: {
    guestChildren?: number;
    guestChildrenBaby?: number;
    home?: string;
  };

  onChange?: (data: GuestsInputPropsBambini["defaultValue"]) => void;
  onBlur?: () => void;
  fieldClassName?: string;
}

const GuestsInputBambini: FC<GuestsInputPropsBambini> = ({
  defaultValue,
  onChange,
  onBlur,
  fieldClassName = "[ nc-hero-field-padding ]",
}) => {

  const [guestChildrenInputValue, setGuestChildrenInputValue] = useState(0);
  const [guestChildrenBabyInputValue, setGuestChildrenBabyInputValue] = useState(0);
  const [home, setGuestHome] = useState('');
  const totalGuests = guestChildrenInputValue + guestChildrenBabyInputValue;
  const [closed, setClosed] = useState(0);
  const location = useLocation<State>();

  useEffect(() => {
    if (defaultValue.home) setGuestHome('home')
    let bambini = 0;
    let bambiniBaby = 0;
    if (location.state) {

      let state: any = null;
      state = location.state;

      if (state.info) {

        bambini = state.info.bambini;
        bambiniBaby = state.info.bambiniBaby;

      }      
    }

    setGuestChildrenInputValue(defaultValue.guestChildren || bambini);
    setGuestChildrenBabyInputValue(defaultValue.guestChildrenBaby || bambiniBaby);
  }, [defaultValue]);

  useEffect(() => {
    if (onChange) {
      onChange({
        guestChildren: guestChildrenInputValue,
        guestChildrenBaby: guestChildrenBabyInputValue
      });
    }
  }, [guestChildrenInputValue, guestChildrenBabyInputValue]);


  useEffect(() => {
    if (closed == 1) {
      if (onBlur) {
        onBlur()
      }
    }

  }, [closed]);

  return (
    <Popover className="flex relative [ nc-flex-1 ]"    >
      {({ open }) => (
        <>
          <Popover.Button
            className={`flex text-left w-full flex-shrink-0 items-center ${fieldClassName} space-x-3 focus:outline-none cursor-pointer ${open ? "shadow-2xl rounded-full dark:bg-neutral-800" : ""
              }`}
          >
            <div className="text-neutral-300 dark:text-neutral-400" >
              <i className="las la-baby text-2xl mr-2"></i>
            </div>
            <div className="flex-grow">
              <span className="block xl:text-lg font-semibold">
                {home ? 'Bambini' : 'Numero bambini'}
              </span>
              <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                {totalGuests ? "Bambini: " + totalGuests : "Aggiungi bambini"}
              </span>
              {!!totalGuests && open && (
                <ClearDataButton
                  onClick={() => {
                    setGuestChildrenInputValue(0);
                    setGuestChildrenBabyInputValue(0);
                  }}
                />
              )}
            </div>

          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
            afterLeave={() => { setClosed(1) }}
            afterEnter={() => { setClosed(0) }}
          >
            <Popover.Panel className="absolute z-10 bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 rounded-3xl shadow-xl space-y-2">
              <NcInputNumber
                key={1}
                className="w-full"
                defaultValue={guestChildrenInputValue}
                onChange={(value) => setGuestChildrenInputValue(value)}
                label="Da&nbsp;4&nbsp;a&nbsp;12&nbsp;anni"
                desc={home ? '' : "Tariffa bambini"}
              />

              <NcInputNumber
                key={2}
                className="w-full"
                defaultValue={guestChildrenBabyInputValue}
                onChange={(val) => setGuestChildrenBabyInputValue(val)}
                label="Da 0 a 3 anni"
                desc={home ? '' : "Gratis"}
              />
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default GuestsInputBambini;
