import React, { FC } from "react";
import BlogSingle from "containers/BlogPage/BlogSingle";
import { useParams } from "react-router-dom";

export interface PageAboutProps {
  className?: string;
}
export interface State {
  evento_id: string,
}
const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {

  let temp = useParams<{ evento_id: string }>();
  const evento_id = temp.evento_id;

  return (
    <BlogSingle evento_id={evento_id} />
  );
};

export default PageAbout;
